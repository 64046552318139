@import "../../../../asset/style/const.scss";

.additional_options {
    &__form {
      padding: 6px 12px;
      position: relative;

      .incurance_wrap {

				@media (min-width: $tablet) {
					display: flex;
          justify-content: start;
					gap: 10px;

          > div {
            width: 50%;
          }
				}
        
        
        @media (min-width: 2500px) {
          display: grid;
          grid-template-columns: 260px auto;
          gap: 20px;
          display: flex;
          flex-wrap: nowrap;
        }

        .add_driver,
        .incurance_wrap {
          position: relative;
          display: flex;
          align-items: center;
          gap: 10px;

          & > p {
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            color: var(--dark);
            white-space: nowrap;
          }
        }

        .incurance_wrap {
          & > div {
            flex-grow: 1;
          }

          input {
            padding: 10px 12px;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: var(--dark);
            opacity: 0.4;
          }
        }
      }
    }

    &__list {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 10px;

			@media (min-width: $tablet) {
				display: flex;
        justify-content: space-between;
				gap: 10px;
			}

			@media (min-width: $desktop-sm) {
				gap: 6px;
			}

      li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 5px;

        h1 {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0em;
          color: var(--dark);
          opacity: 0.7;
          small {
            font-size: 11px;
            margin-inline-start: 4px;
          }
          &.warning {
            opacity: 0.5;
            font-size: 11px;
            font-weight: 600;
          }
        }
      }
    }

    &__travel {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      font-size: 12px;

      .checkbox {
        margin-top: 12px;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .destination {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 60%;

        p {
          white-space: nowrap;
        }
      }
    }
  }