.auditor-wrapper {
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 50%;

  h2 {
    font-size: 14px;
    font-weight: 500;
  }

  .status {
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 8px;
    color: var(--primary);
    background-color: var(--primary-10);
  }

  .auditor__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    .debt {
      font-size: 12px;
      font-weight: 600;
      color: var(--danger);
      padding: 5px 10px;
      border-radius: 12px;
      border: 2px solid var(--danger)
    }
  }
}

.auditor-block {
  display: flex;
  align-items: center;
  gap: 10px;

  .auditor {
    padding: 6px 6px;
		padding-right: 0;
    font-size: 12px;
    border: 1px solid var(--dark-20);
    border-radius: 12px;
    width: 50%;

		h3 {
			padding-left: 0;
		}

    &__icon-check {
			.icon {
				margin-left: 5px;
				background-color: var(--primary);
      }
    }

    &__control-type {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}