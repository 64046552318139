@import "../../asset/style/const.scss";

.nav-bar {
  display: flex;
  flex-direction: column;

  @media (min-width: $desktop-sm) {
    display: flex;
    flex-direction: row;
  }
}

.full-layout {
  &__children {
    padding: 16px;
    margin-top: 60px;

    &[data-zoom="true"] {
      zoom: 0.8;
    }


    @media (min-width: $tablet) {
      padding: 5%;
      padding-top: 0;
    }

    @media (min-width: $desktop-sm) {
      padding: 16px;
      margin: 0 auto;
      width: 100%;
    }

    @media (min-width: 2500px) {
      max-width: 2500px;
    }
  }
}
