@import "./color.css";
@import "./font.css";
@import "./icon.css";
@import "./const.scss";

*, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: var(--bg);
    overflow-x: hidden;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

input, textarea, select,
input[type='text'],
input[type='number'],
input:focus {
    font-size: 16px;
  }

// под вопросом насколько нужен этот контейнер
.container {
    min-width: 100vw;
    max-width: 100vw;
    margin: 0 auto;
    
    @media (min-width : $tablet) {
        padding: 0 20px;
    }
    @media (min-width : $desktop-sm) {
        min-width: 1000px;
        max-width: 1330px;
    }
    @media (min-width : $desktop-md) {
        min-width: 1200px;
        max-width: 1200px;
    }
}


.kill-scroll {
    height: 100vh;
    overflow: hidden;
}


.ReactCollapse--collapse {
    transition: height 200ms;
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate--90 {
    transform: rotate(-90deg);
}

.p-r {
    position: relative;
}

.c-p {
    cursor: pointer;
}