@import "../../asset/style/const.scss";

.modal-full-wrap {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 5;
    background-color: var(--dark-60);
    transition: 0.3s linear;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    visibility: hidden;
    opacity: 0.5;
}

.modal-full__main {
  max-width: 800px;
  width: 100%;
  background-color: var(--light);
  max-height: 100vh;
  overflow-y: auto;
  position: absolute;
  right: -100%;
  height: 100%;
  transition: 0.3s linear;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

}

.modal-full__nav {
	position: sticky;
	top: 0;
	z-index: 2;
  padding: 10px;
  padding-left: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 30px;
  background-color: var(--primary);

  h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    color: var(--light);
  }

  div {
    background: var(--primary);
  }

  div i {
    background-color: var(--light);
  }
}

.modal-full-wrap.modal-open {
    opacity: 1;
    visibility: visible;

    .modal-full__main {
      right: 0;
    }
}
