@import "../../../../../asset/style/const.scss";

.catalog__top__wrap {
	padding: 20px;
	position: relative;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 3;
	background-color: var(--light);
	box-shadow: 0px 2px 5px var(--dark-5);

}

.catalog__top {
	&__title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
	}
	
	&__main {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;		
		
		@media(min-width: $desktop-sm) {
				&.catalog__top__search {
						grid-template-columns: 1fr 48px;
				}

			padding: 10px;
			display: -ms-grid;
			display: grid;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			grid-template-columns: 197px 1fr 197px 80px 40px;
			gap: 10px;
		}
			
    @media(min-width: $desktop-md) {
			grid-template-columns: repeat(3, 1fr) 105px 55px;
      gap: 5px;
    }

    &__gearbox {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }
    }
	}

	&__main-mobile {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 10px;
		margin-bottom: 10px;

		> div:first-child {
			width: 100%;
			select {
				width: 100%;
			}
		}
		> div:nth-child(2) {
			width: 50%;
		}
		> div:nth-child(3) {
			width: 46%;
		}
	}

	&__filter {
		display: flex;
		gap: 10px;
		justify-content: space-between;
	}
	
}
.age_exp_checkbox__wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	gap: 5px;

	p {
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0em;
		color: var(--gray);
	}
}
