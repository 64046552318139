@import "../../../../../asset/style/const.scss";

.rent-period-form {
	  padding: 10px 15px;
	  display: flex;
	  flex-direction: column;
	  justify-content: space-between;
	  gap: 10px;
	  position: relative;

	  @media (min-width: $tablet) {
		flex-direction: row;
	  }
}

.rent-period-form__container {
	display: flex;
	flex-direction: column;
	gap: 20px;

	@media (min-width: $tablet) {
		width: 100%;
	}

	
	@media (min-width: 1800px) {
		display: -ms-grid;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 15px;
		flex-grow: 1;
	}
}

.rent-period-form__date {
	display: flex;
	flex-direction: column;
	gap: 8px;

	@media (min-width: $tablet) {
		flex-direction: row;
		align-items: center;
		gap: 10px;
	}

	h1 {
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		letter-spacing: 0em;
		padding: 0 3px;
		color: var(--dark);
	}

	.rent-period-form__input {
		border: 1px solid var(--primary-dark-15);
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-grow: 1;
		padding: 10px 11px;
		cursor: pointer;
		gap: 10px;

		p {
			font-size: 13px;
			font-weight: 400;
			line-height: 17px;
			letter-spacing: 0em;
			color: var(--dark);
		}

		i {
			background-color: var(--primary-dark-15);
		}
	}

	.rent-period-form__input.disabled-input {
		background-color: var(--dark-5);
	}
}


.rent-period-form__period {
	margin-left: auto;
	width: 50%;
	padding: 12px 7px;
	font-size: 1em;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: center;
	color: var(--primary);
	background-color: var(--primary-10);
	border-radius: 12px;
	white-space: nowrap;

	@media (min-width: $tablet) {
		width: 10%;
		padding-top: 43px;
	}

	@media (min-width: $desktop-md) {
		width: 10%;
		padding-top: 12px;
	}
}