.block-filter-mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 75%;
}

.block-filter-mobile__filter {
    padding: 10px;
    border: 1px solid var(--primary-dark-15);
    border-radius: 12px;
    cursor: pointer;
  }