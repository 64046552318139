@import "../../../../../asset/style/const.scss";


.client-base-info__form {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	justify-content: space-between;
	gap: 10px;
	position: relative;	
	padding: 20px;

	@media(min-width: $desktop-sm) {
		padding: 10px;
	}

	.wrapper-age {
		display: flex;
		gap: 10px;
	}
}

.appeal-input {
	width: 100%;
	display: flex;
	align-items: flex-end;
	position: relative;
	gap: 5px;

	@media(min-width: $tablet) {
		width: 50%;
	}
	@media(min-width: $desktop-sm) {
		width: 50%;
	}
}

.age__exp__tabs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 5px;
	margin-bottom: 5px;

	p {
		font-size: 13px;
		font-weight: 400;
		line-height: 15px;
		letter-spacing: 0em;
		color: var(--gray);
	}
}

.rent__terms {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	gap: 5px;
	background-color: var(--dark-5);
	padding: 6px;
	border-radius: 12px;
	cursor: pointer;

	p {
		font-size: 11px;
		font-weight: 400;
		line-height: 12px;
		letter-spacing: 0em;
		
		a {
			color: var(--dark);
		}

		@media(min-width: $tablet) {
			font-size: 13px;
		}
	}

	.icon__wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;

		i {
			background-color: var(--dark);
		}
	}
}
