


.create__client__modal__form {
	background-color: var(--light);
	border-radius: 20px;
	padding: 30px;
	padding-bottom: 100px;
	min-width: 375px;
	max-width: 500px;
}

.create__client__modal__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;

	&__title {
		font-size: 26px;
		font-weight: 600;
		line-height: 32px;
		letter-spacing: 0em;
		color: var(--dark);
	}
}

  .create__client__modal {
    &__main {
      margin-bottom: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 20px;
    }

    &__input {
      & > p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        padding: 0 10px;
        margin-bottom: 10px;
      }
    }

    &__footer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 20px;
    }
  }

  .error_in_res__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;

    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: var(--dark);
      margin-top: 30px;
      text-align: center;
    }

    .btns__grp {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 80px;
    }
  }