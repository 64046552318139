@import "../../../asset/style/const.scss";

.comparsion {
    &__main {
			gap: 10px;

      &__right {
        max-width: auto;
        min-width: auto;
        overflow: auto;
        padding: 10px 0;
        background: var(--light);
        box-shadow: 0px 2px 10px var(--primary-dark-5);
        height: 100%;
      }

			@media (min-width: $desktop-sm) {
				display: -ms-grid;
				display: grid;
				grid-template-columns: 300px 1fr;
				min-height: 100vh;

				&__right {
					max-width: calc(100vw - 390px);
					overflow: auto;
					padding: 10px 0;
					background: var(--light);
					box-shadow: 0px 2px 10px var(--primary-dark-5);
					height: 100%;
					min-width: 1530px;
				}

			}

      &__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        width: max-content;

        li {
          border: 2px solid transparent;
          border-radius: 10px;
          overflow: hidden;

          .add__car__btn {
            border: 1px dashed var(--primary);
            border-radius: 20px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            gap: 10px;
            cursor: pointer;
            margin-left: 10px;
            width: 210px;
            height: 210px;

            p {
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
              letter-spacing: 0em;
              color: var(--primary);
              opacity: 0.6;
            }
          }

          &.isPlace {
            border-color: var(--primary);
          }

          &.overPlace {
            background-color: var(--dark-10);
          }
        }
      }
    }
  }