@import "../../asset/style/const.scss";

.car__equipment {
    &__nav {
      padding: 10px;
      padding-left: 26px;
      padding-top: 0;
      margin-bottom: 10px;
      margin-top: -25px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;

      &__h1 {
        font-size: 16px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 5px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;
      }

      &__p {
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        color: var(--dark-70);
        margin-bottom: 20px;
      }

        @media (min-width: $desktop-sm) {
            padding-left: 50px;
            padding-right: 50px;
            flex-direction: row;
            gap: 20px;
            margin-bottom: 30px;
            
            &__h1 {
                font-size: 22px;
            }
            
            &__p {
                font-size: 20px;
            }

        }
    }

    &__booking__frame {
      margin: 0 30px 30px;
      border: 1px solid var(--warning);
      border-radius: 30px;
      padding: 30px;
      display: flex;
      align-items: center;
      gap: 30px;

      h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 5px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark-70);
      }
    }

    &__tab {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 25px;
    }

    &__ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 5px;
      padding: 0 20px 30px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 10px 20px;
        border-radius: 14px;

        h1 {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        p {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0em;
          white-space: nowrap;
          color: var(--dark);

          .color__square {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-color: #d2d2d2;
            border-radius: 6px;
          }
        }

        &:nth-child(2n) {
          background-color: var(--dark-3);
        }

        @media (min-width: $desktop-sm) {
          padding: 20px;

          h1 {
            font-size: 20px;
            line-height: 24px;
          }
        
          p {
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
        
            .color__square {
              display: inline-block;
              width: 20px;
              height: 20px;
              background-color: #d2d2d2;
              border-radius: 6px;
            }
          }
        }
      }
    }
}