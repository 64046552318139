
.finance__report__modal__wrap {
    padding: 30px;
    max-width: 500px;
    min-width: 450px;
}

.finance__report__modal {
  &__content {
    padding: 30px 30px 60px;
  }

  &__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;

    h1 {
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      color: var(--dark);
    }
  }

  &__main {
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: var(--dark);
    }
  }

  &__footer {
    display: flex;
    justify-content: end;
  }
}