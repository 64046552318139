.counterparty-info {
    padding: 8px 12px;

    &__block {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
    }

    &__header-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-top: 6px;
    }

    &__header {
        width: 100%;
    }

    &__checkbox {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 10px;

			p {
				font-size: 12px;
				font-weight: 400;
				color: var(--dark);
        white-space: nowrap;
			}
    }

    &__footer-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-top: 10px;


    }

    &__footer {
      display: flex;
			align-items: center;
			gap: 10px;
    }
}