@import "../../asset/style/const.scss";

.user-info-modal {
    padding: 40px 60px 60px;
    min-height: calc(100vh - 112px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  
    section {
      &:nth-child(1) {
        margin-bottom: 60px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        background-color: var(--bg);
        width: calc(100% + 60px);
        transform: translateX(-30px);
        padding: 20px;
        border: 1px solid var(--primary-dark-15);
        border-radius: 30px;
  
        .user__server__infos {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 30px;
        }
  
        .edit__logout__wrapper {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 20px;
        }
  
        h1 {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
  
          span {
            font-size: 22px;
            font-weight: 600;
            line-height: 27px;
          }
  
          color: var(--dark);
        }
      }
  
      &:nth-child(2) {
        .info__list {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          gap: 70px;
  
          & > li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            gap: 20px;
  
            h1 {
              font-size: 22px;
              font-weight: 600;
              line-height: 27px;
              letter-spacing: 0em;
              color: var(--dark);
              margin-bottom: 5px;
            }
  
            p {
              font-size: 18px;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: 0em;
              color: var(--dark-70);
            }
  
            .organition__list {
              li {
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                color: var(--dark-70);
              }
            }
          }
        }
      }
    }
  
    & > footer {
      width: calc(100% + 60px);
      transform: translateX(-30px);
      border: 1px solid var(--primary-dark-15);
      border-radius: 20px;
      margin-top: 60px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      .footer__top {
        display: flex;
        align-items: center;
        gap: 20px;
  
        h1 {
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0em;
          color: var(--dark);
          margin-bottom: 5px;
        }
  
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark-70);
        }
      }
  
      .footer__bottom {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-left: 80px;
  
        .phone,
        .gmail {
          background: var(--dark-5);
          border-radius: 17px;
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 5px 10px;
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
  
          i {
            background-color: var(--dark);
          }
        }
      }
    }
  
    @media (max-width: $tablet) {
      padding: 0 30px 0 15px;
      min-height: calc(100vh - 90px);
  
      section {
        &:nth-child(1) {
          border: none;
          border-radius: 0;
          padding: 20px 30px;
  
          button {
            width: 44px;
            height: 44px;
            padding: 0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
  
            i {
              width: 24px;
              height: 24px;
            }
          }
        }
  
        &:nth-child(2) {
        	.info__list {
            gap: 50px;
  
            > li {
              h1 {
                font-size: 20px;
                line-height: 24px;
              }
  
              p {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }
      }
  
      footer {
        background-color: var(--bg);
        border: 0;
        padding: 20px 30px;
  
        .footer__bottom {
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;
        }
      }
    }
  }