@import "../../asset/style/const.scss";

.req-collapse-layout {
    display: flex;
    flex-direction: column;
    // gap: 15px;

    &__left {
        margin-top: 10px;
    }
}


@media (min-width: $desktop-sm) {

    .req-collapse-layout {
      display: -ms-grid;
      display: grid;
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 20px;
      max-height: 630px;
      overflow-y: auto !important;
      position: relative;
    
      &::-webkit-scrollbar {
        display: none;
      }
    
      .req__collapse {
        &__left,
        &__right {
            margin-top: 0px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          gap: 20px;
        }
    
        &__left {
          padding: 20px;
          padding-right: 0;
          position: sticky;
          position: -webkit-sticky;
          top: 0;
          left: 0;
          height: max-content;
          z-index: 2;
        }
    
        &__right {
          padding: 20px;
          padding-left: 0;
        }
      }
    }
}