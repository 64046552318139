@import "../../../../../asset/style/const.scss";


.child-seat-block {

    @media (min-width: $tablet) {
      display: -ms-grid;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 5px;
      position: relative;

      &.is-tariff {
        grid-template-columns: 1fr;
      }
    }


  li {
    background-color: var(--light);
    // box-shadow: 0px 2px 5px var(--primary-dark-5);
    border-radius: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px;
    padding-left: 10px;
    margin-bottom: 0px;

    section {
      display: flex;
      align-items: center;
      gap: 8px;

      & > i {
        background-color: var(--dark);
      }

      h1 {
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--dark);
        opacity: 0.7;
      }

      h2 {
        font-size: 22px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        color: var(--dark);
        width: 20px;
        text-align: center;
      }
    }
  }
}