.type-booking-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
	justify-content: space-between;
}

.type-booking__type {
	padding: 10px 15px;
  display: flex;
  gap: 15px;
	flex-grow: 2;
	background: var(--light);
  box-shadow: 0px 2px 10px var(--dark-5);
	border-radius: 20px;

	.type-booking__form {
		width: 50%;
	}
}

.type-booking__deposit {
  padding: 10px 15px;
  display: flex;
  gap: 15px;
	width: 30%;
	background: var(--light);
  box-shadow: 0px 2px 10px var(--dark-5);
	border-radius: 20px;
}

