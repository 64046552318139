
.selected_car_card {
    position: sticky;
    position: -webkit-sticky;
    top: 60px;
    width: 100%;
    // min-height: 220px;
    z-index: 2;
    background-color: var(--light);
    box-shadow: 0px 0px 20px var(--secondary-dark-10);

    &__top {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 10px 20px 0 15px;
      margin-bottom: 20px;

      @media(min-width: 991px) {
        flex-direction: column;
      }

      &__left {
        display: flex;
        align-items: center;
        gap: 15px;

        .name_class_address__wrap {
          & > h1 {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
            margin-bottom: 5px;
          }

          & > p {
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            color: var(--dark);
            opacity: 0.6;
          }

          .address__wrap {
            border: 1px solid var(--success-20);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 5px;
            border-radius: 20px;
            padding: 3px 20px 3px 3px;
            margin-top: 10px;

            h1 {
              font-size: 10px;
              font-weight: 400;
              line-height: 12px;
              letter-spacing: 0em;
              color: var(--dark);
              opacity: 0.5;
            }

            p {
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
              letter-spacing: 0em;
              color: var(--dark);
            }
          }
        }
      }

      &__right {
        margin-top: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        flex-wrap: wrap;

        .info_icons__wrap {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          gap: 5px;
        }

        .selected_car__gosnomer {
          width: 220px;
          font-size: 14px;
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;

          div:first-child {
            width: 135px;
          }
        }

        button {
          width: 100%;
        }
      }
    }

    &__bottom {
      padding: 0px 15px 20px;

      ul {
        border-top: 1px solid var(--primary-dark-15);
        display: -ms-grid;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        padding-top: 10px;

        li {
          display: flex;
          align-items: center;
          gap: 20px;

          h1 {
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
            color: var(--dark);
            opacity: 0.5;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            color: var(--dark);
          }
        }
      }
    }
  }