@import "../../../../../asset/style/const.scss";

.req__list {
    &__body {
      margin-top: 10px;
    }

    &__title {
      padding: 20px;
      padding-bottom: 0px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;
			margin-bottom: 15px;

      h1 {
        font-size: 38px;
        font-weight: 600;
        line-height: 46px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      p {
				margin-right: auto;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark);
        opacity: 0.7;
      }
    }

    &__filter {
      padding-left: 20px;
      padding-right: 20px;
			display: flex;
			justify-content: space-between;
			gap: 10px;

			&__status, &__search {
				width: 85%;
			}
    }

		
    &__ul {
			margin-top: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      > li {
        border-top: 1px solid var(--dark-15);
      }
      
    }

		@media (min-width: $desktop-sm) {
			&__body {
				padding: 30px;
			}

			&__nav {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
				gap: 20px;
			}

			&__filter__status {
				display: -ms-grid;
				display: grid;
				gap: 10px;
				grid-template-columns: 80px 96px 250px;
			}
				
			&__filter {
				&__search {
					display: -ms-grid;
					display: grid;
					gap: 10px;
					grid-template-columns: 550px;
				}
			}

      &__ul {
        gap: 10px;

        > li {
          border-top: none;
          border-radius: 20px;
        }
        
        > li:nth-child(odd) {
          background-color: var(--dark-3);
        }
      }

		}
  }