.car__filter__collapse {
    &__nav {
      padding: 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 20px;

      h1 {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      i {
        background-color: var(--dark);
      }
    }

    &__ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 5px;
      padding: 0 8px 20px;

      li {
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 5px;
        border-radius: 16px;
        padding-top: 6px;
        padding-bottom: 6px;
        transition: 0.2s linear;

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        &:hover {
          background-color: var(--dark-3);
        }

        &.active {
          background-color: var(--primary-10);

          p {
            color: var(--primary);
          }

          &:hover {
            background-color: var(--primary-20);
          }
        }
      }
    }
  }