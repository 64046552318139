@import "../../asset/style/const.scss";

.catalog-mobile {
  &__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 10px;
    padding: 12px 0;
    background-color: var(--light);
		border-bottom: 1px solid rgba(26, 43, 70, 0.08);

		&--modal {
			padding: 15px;
		}
  }

	&__nav-left {
    display: flex;
    align-items: flex-start;
		flex-direction: column;
    gap: 10px;
		width: 50%;
	
		.car-info {
			display: flex;
			align-items: center;
			gap: 15px;
		}

		.car-info__name {
			h1 {
				font-size: 14px;
				line-height: 17px;
				margin-bottom: 2px;
				position: relative;
				z-index: 1;
				white-space: nowrap;
			}
							
			p {
				display: flex;
				align-items: center;
				font-size: 12px;
				line-height: 15px;
				gap: 5px;
				white-space: nowrap;
				text-overflow: ellipsis;
				position: relative;

				.circle {
					display: block;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					background-color: var(--dark-60);
				}
			}
					
			p:hover {
				width: 285px;
				overflow: visible;
				color: var(--dark);
				z-index: 1;
			}
		}

		.car-info__status {
      width: 10px;
      height: 30px;
      border-radius: 5px;
      background-color: var(--success);

      &.warning {
        background-color: var(--warning);
      }

      &.danger {
        background-color: var(--danger);
      }
    }
		.car-price {
			display: flex;
			align-items: center;
			gap: 10px;

			.car-isauto-wrap {
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background-color: var(--primary-10);
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
				letter-spacing: 0em;
				color: var(--primary);
		
				&.success {
					background-color: var(--success-20);
					color: var(--success);
				}
			}

			&__frame {
				display: flex;
				font-size: 12px;
				font-weight: 500;
				line-height: 15px;
				letter-spacing: 0em;
				text-align: center;
				color: var(--dark-60);
				min-width: 65px;
				white-space: nowrap;
				padding: 7px 12px;
				gap: 8px;
				background: rgba(26, 43, 70, 0.05);
				border-radius: 14px;

				.per_day_container {
					&::before {
						content: "(";
					}
					&::after {
						content: ")";
					}
				}
			}

			.rent__sum__wrap {
				padding: 8px;
				font-size: 14px;
				white-space: nowrap;
				background: var(--primary-dark-10);
				border-radius: 20px;
			}
		}

		.car-info__isauto {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: var(--primary-10);
			font-size: 16px;
			font-weight: 600;
			line-height: 20px;
			letter-spacing: 0em;
			color: var(--primary);
			
			&.success {
				background-color: var(--success-20);
				color: var(--success);
			}
		}

		.car-info__conditions {
			color: var(--primary);
			cursor: pointer;
		}
	}



  &__nav-right {
		.car__image__wrap {
			position: relative;
			width: 29%;

			p {
				width: 25px;
				height: 25px;
				border-radius: 50%;
				background-color: var(--light);
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				border: 1px solid var(--primary-dark-15);
				font-size: 14px;
				font-weight: 500;
				line-height: 17px;
				letter-spacing: 0em;
				color: var(--dark);
				position: absolute;
				bottom: -3px;
				right: 15px;
			}
		} 
  }
}
  

.catalog-mobile__main {
    padding: 10px;
    background-color: var(--bg);
    display: flex;
    flex-direction: column;
    gap: 20px;

		@media (min-width: $desktop-sm) {
			padding: 20px;
		}
}

.catalog-mobile__button {
	padding: 10px;
	
	button {
		text-align: center;
		width: 100%;
	}
}

.catalog-mobile__car-list {
	position: relative;
	display: flex;
	flex-direction: column;
}

.catalog-mobile__empty-block {
	width: 100%;
	height: 20px;
}