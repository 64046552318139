@import "../../../asset/style/const.scss";

.booking-wrap {
  position: relative;

  h1 {
    font-size: 14px;
  }


  h3 {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
    margin-bottom: 4px;
    padding-left: 8px;
  }
       
  p {
    font-size: 12px;
  }

  @media (min-width: 991px) {
    display: grid;
    grid-template-columns: 1fr 1fr 350px;
    grid-template-rows: 1fr;
    justify-content: center;
    gap: 10px;
    background-color: var(--bg);
    position: relative;
    transform: translateX(0.00001%);
    transition: 5s;
    height: 100%;

    .left__filter,
    .right__filter {
      padding-top: 15px;
    }

    .middle-block {
      margin-top: 15px;
      height: 550px;

      .req-create-middle {
        height: 550px;
      }
      .req-create-middle.exist-car {
        height: calc(45vh - 50px);
      }

      .req-create-middle .car__list {
        height: 550px;
      }

      .emty-selected-car {
        width: 100%;
        padding: 30px;
        text-align: center;
        border-radius: 20px;
        background-color: var(--light);
        box-shadow: 0px 0px 20px var(--secondary-dark-10);
      }
    }

    .right-block {
      margin-top: 15px;
    }
  }

    @media (min-width: 1600px) {
      transition: 0.3s;
      grid-template-columns: 1fr 1fr 350px;
      grid-template-rows: 1fr;
      gap: 12px;
    }

    @media (min-width: 1700px) {
      transition: 0.3s;
      grid-template-columns: 1fr 1fr 400px;
      grid-template-rows: 1fr;
    }

}
