.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: var(--light);
  cursor: pointer;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  transition: 0.2s linear;
}

.icon-dark {
  background-color: var(--dark);
}

.icon-xxs {
  width: 12px;
  height: 12px;
}

.icon-xms {
  width: 14px;
  height: 14px;
}

.icon-xs {
  width: 16px;
  height: 16px;
}

.icon-xsm {
  width: 18px;
  height: 18px;
}

.icon-sm {
  width: 20px;
  height: 20px;
}

.icon-lg {
  width: 30px;
  height: 30px;
}

.icon-xl {
  width: 40px;
  height: 40px;
}

.icon-xxl {
  width: 50px;
  height: 50px;
}

.icon-search {
  mask-image: url("../icon/search-md.svg");
  -webkit-mask-image: url("../icon/search-md.svg");
}

.icon-refresh {
  mask-image: url("../icon/refresh.svg");
  -webkit-mask-image: url("../icon/refresh.svg");
}

.icon-car {
  mask-image: url("../icon/car.svg");
  -webkit-mask-image: url("../icon/car.svg");
}

.icon-auditor {
  mask-image: url("../icon/auditor.svg");
  -webkit-mask-image: url("../icon/auditor.svg");
}

.icon-bell {
  mask-image: url("../icon/bell.svg");
  -webkit-mask-image: url("../icon/bell.svg");
}

.icon-pizza {
  mask-image: url("../icon/pizza.svg");
  -webkit-mask-image: url("../icon/pizza.svg");
}

.icon-analytic {
  mask-image: url("../icon/analytic.svg");
  -webkit-mask-image: url("../icon/analytic.svg");
}

.icon-nav-link-bg {
  mask-image: url("../icon/nav-link-bg.svg");
  -webkit-mask-image: url("../icon/nav-link-bg.svg");
}

.icon-arrow-right {
  mask-image: url("../icon/arrow-right.svg");
  -webkit-mask-image: url("../icon/arrow-right.svg");
}

.icon-arrow-left {
  mask-image: url("../icon/arrow-left.svg");
  -webkit-mask-image: url("../icon/arrow-left.svg");
}

.icon-arrow-down {
  mask-image: url("../icon/arrow-down.svg");
  -webkit-mask-image: url("../icon/arrow-down.svg");
}

.icon-arrow-up {
  mask-image: url("../icon/arrow-up.svg");
  -webkit-mask-image: url("../icon/arrow-up.svg");
}

.icon-booking {
  mask-image: url("../icon/booking.svg");
  -webkit-mask-image: url("../icon/booking.svg");
}

.icon-parking {
  mask-image: url("../icon/parking.svg");
  -webkit-mask-image: url("../icon/parking.svg");
}

.icon-doc {
  mask-image: url("../icon/doc.svg");
  -webkit-mask-image: url("../icon/doc.svg");
}

.icon-currency-ruble {
  mask-image: url("../icon/currency-ruble.svg");
  -webkit-mask-image: url("../icon/currency-ruble.svg");
}

.icon-flip-forward-right {
  mask-image: url("../icon/flip-forward-right.svg");
  -webkit-mask-image: url("../icon/flip-forward-right.svg");
}

.icon-chevron-right {
  mask-image: url("../icon/chevron-right.svg");
  -webkit-mask-image: url("../icon/chevron-right.svg");
}

.icon-chevron-down {
  mask-image: url("../icon/chevron-down.svg");
  -webkit-mask-image: url("../icon/chevron-down.svg");
}

.icon-circle-line {
  mask-image: url("../icon/circle-line.svg");
  -webkit-mask-image: url("../icon/circle-line.svg");
}

.icon-close {
  mask-image: url("../icon/close.svg");
  -webkit-mask-image: url("../icon/close.svg");
}

.icon-upload {
  mask-image: url("../icon/upload.svg");
  -webkit-mask-image: url("../icon/upload.svg");
}

.icon-wallet {
  mask-image: url("../icon/wallet.svg");
  -webkit-mask-image: url("../icon/wallet.svg");
}

.icon-download {
  mask-image: url("../icon/download.svg");
  -webkit-mask-image: url("../icon/download.svg");
}

.icon-pdf {
  mask-image: url("../icon/pdf.svg");
  -webkit-mask-image: url("../icon/pdf.svg");
}

.icon-reverse-left {
  mask-image: url("../icon/reverse-left.svg");
  -webkit-mask-image: url("../icon/reverse-left.svg");
}

.icon-calendar {
  mask-image: url("../icon/calendar.svg");
  -webkit-mask-image: url("../icon/calendar.svg");
}

.icon-home {
  mask-image: url("../icon/home.svg");
  -webkit-mask-image: url("../icon/home.svg");
}

.icon-tool {
  mask-image: url("../icon/tool.svg");
  -webkit-mask-image: url("../icon/tool.svg");
}

.icon-tools {
  mask-image: url("../icon/tools.svg");
  -webkit-mask-image: url("../icon/tools.svg");
}

.icon-phone {
  mask-image: url("../icon/phone.svg");
  -webkit-mask-image: url("../icon/phone.svg");
}

.icon-navigation {
  mask-image: url("../icon/navigation.svg");
  -webkit-mask-image: url("../icon/navigation.svg");
}

.icon-shield {
  mask-image: url("../icon/notif-otchet.svg");
  -webkit-mask-image: url("../icon/notif-otchet.svg");
}

.icon-aprove-report {
  mask-image: url("../icon/aprove-the-report.svg");
  -webkit-mask-image: url("../icon/aprove-the-report.svg");
}

.icon-plus-circle {
  mask-image: url("../icon/plus-circle.svg");
  -webkit-mask-image: url("../icon/plus-circle.svg");
}

.icon-minus-circle {
  mask-image: url("../icon/minus-circle.svg");
  -webkit-mask-image: url("../icon/minus-circle.svg");
}

.icon-file {
  mask-image: url("../icon/file.svg");
  -webkit-mask-image: url("../icon/file.svg");
}

.icon-file-check {
  mask-image: url("../icon/file-check.svg");
  -webkit-mask-image: url("../icon/file-check.svg");
}

.icon-bar-line-chart {
  mask-image: url("../icon/bar-line.svg");
  -webkit-mask-image: url("../icon/bar-line.svg");
}

.icon-check {
  mask-image: url("../icon/check.svg");
  -webkit-mask-image: url("../icon/check.svg");
}

.icon-check-circle {
  mask-image: url("../icon/check-circle.svg");
  -webkit-mask-image: url("../icon/check-circle.svg");
}

.icon-chevron-selector {
  mask-image: url("../icon/chevron-selector.svg");
  -webkit-mask-image: url("../icon/chevron-selector.svg");
}

.icon-filter-lines {
  mask-image: url("../icon/filter-lines-down.svg");
  -webkit-mask-image: url("../icon/filter-lines-down.svg");
}

.icon-message-dots-circle {
  mask-image: url("../icon/message-dots-circle.svg");
  -webkit-mask-image: url("../icon/message-dots-circle.svg");
}

.icon-percent-circle {
  mask-image: url("../icon/percent-circle.svg");
  -webkit-mask-image: url("../icon/percent-circle.svg");
}

.icon-receipt-check {
  mask-image: url("../icon/receipt-check.svg");
  -webkit-mask-image: url("../icon/receipt-check.svg");
}

.icon-plus {
  mask-image: url("../icon/plus.svg");
  -webkit-mask-image: url("../icon/plus.svg");
}

.icon-minus {
  mask-image: url("../icon/minus.svg");
  -webkit-mask-image: url("../icon/minus.svg");
}

.icon-alert-circle {
  mask-image: url("../icon/alert-circle.svg");
  -webkit-mask-image: url("../icon/alert-circle.svg");
}

.icon-calendar-tool {
  mask-image: url("../icon/calendar-tool.svg");
  -webkit-mask-image: url("../icon/calendar-tool.svg");
}

.icon-server {
  mask-image: url("../icon/server.svg");
  -webkit-mask-image: url("../icon/server.svg");
}

.icon-logout {
  mask-image: url("../icon/log-out.svg");
  -webkit-mask-image: url("../icon/log-out.svg");
}

.icon-pencil {
  mask-image: url("../icon/pencil.svg");
  -webkit-mask-image: url("../icon/pencil.svg");
}

.icon-gmail {
  mask-image: url("../icon/gmail.svg");
  -webkit-mask-image: url("../icon/gmail.svg");
}

.icon-archive {
  mask-image: url("../icon/archive.svg");
  -webkit-mask-image: url("../icon/archive.svg");
}

.icon-archive-outlined {
  mask-image: url("../icon/archive-outlined.svg");
  -webkit-mask-image: url("../icon/archive-outlined.svg");
}

.icon-link {
  mask-image: url("../icon/link.svg");
  -webkit-mask-image: url("../icon/link.svg");
}

.icon-user-nav {
  mask-image: url("../icon/user-nav.svg");
  -webkit-mask-image: url("../icon/user-nav.svg");
}

.icon-announcement {
  mask-image: url("../icon/announcement.svg");
  -webkit-mask-image: url("../icon/announcement.svg");
}

.icon-clock-circle {
  mask-image: url("../icon/clock-circle.svg");
  -webkit-mask-image: url("../icon/clock-circle.svg");
}

.icon-clock-circle-z {
  mask-image: url("../icon/clock-circle-z.svg");
  -webkit-mask-image: url("../icon/clock-circle-z.svg");
}

.icon-clock-circle-check {
  mask-image: url("../icon/clock-circle-check.svg");
  -webkit-mask-image: url("../icon/clock-circle-check.svg");
}

.icon-flag {
  mask-image: url("../icon/flag.svg");
  -webkit-mask-image: url("../icon/flag.svg");
}

.icon-share {
  mask-image: url("../icon/share.svg");
  -webkit-mask-image: url("../icon/share.svg");
}

.icon-slash-circle {
  mask-image: url("../icon/slash-circle.svg");
  -webkit-mask-image: url("../icon/slash-circle.svg");
}

.icon-credit-card-upload {
  mask-image: url("../icon/credit-card-upload.svg");
  -webkit-mask-image: url("../icon/credit-card-upload.svg");
}

.icon-trash {
  mask-image: url("../icon/trash.svg");
  -webkit-mask-image: url("../icon/trash.svg");
}

.icon-pin {
  mask-image: url("../icon/pin.svg");
  -webkit-mask-image: url("../icon/pin.svg");
}

.icon-scales {
  mask-image: url("../icon/scales.svg");
  -webkit-mask-image: url("../icon/scales.svg");
}

.icon-scales-plus {
  mask-image: url("../icon/scales-plus.svg");
  -webkit-mask-image: url("../icon/scales-plus.svg");
}

.icon-scales-minus {
  mask-image: url("../icon/scales-minus.svg");
  -webkit-mask-image: url("../icon/scales-minus.svg");
}

.icon-help-circle {
  mask-image: url("../icon/help-circle.svg");
  -webkit-mask-image: url("../icon/help-circle.svg");
}

.icon-user {
  mask-image: url("../icon/user.svg");
  -webkit-mask-image: url("../icon/user.svg");
}

.icon-sedan {
  mask-image: url("../icon/sedan.svg");
  -webkit-mask-image: url("../icon/sedan.svg");
}

.icon-hatchback-5 {
  mask-image: url("../icon/hatchback-5.svg");
  -webkit-mask-image: url("../icon/hatchback-5.svg");
}

.icon-hatchback-3 {
  mask-image: url("../icon/hatchback-3.svg");
  -webkit-mask-image: url("../icon/hatchback-3.svg");
}

.icon-liftback {
  mask-image: url("../icon/liftback.svg");
  -webkit-mask-image: url("../icon/liftback.svg");
}

.icon-jeep-5 {
  mask-image: url("../icon/Jeep-5.svg");
  -webkit-mask-image: url("../icon/Jeep-5.svg");
}

.icon-jeep-3 {
  mask-image: url("../icon/Jeep-3.svg");
  -webkit-mask-image: url("../icon/Jeep-3.svg");
}

.icon-van {
  mask-image: url("../icon/van.svg");
  -webkit-mask-image: url("../icon/van.svg");
}

.icon-minivan {
  mask-image: url("../icon/minivan.svg");
  -webkit-mask-image: url("../icon/minivan.svg");
}

.icon-pickup {
  mask-image: url("../icon/pickup.svg");
  -webkit-mask-image: url("../icon/pickup.svg");
}

.icon-minibus {
  mask-image: url("../icon/minibus.svg");
  -webkit-mask-image: url("../icon/minibus.svg");
}

.icon-compartment {
  mask-image: url("../icon/compartment.svg");
  -webkit-mask-image: url("../icon/compartment.svg");
}

.icon-convertible {
  mask-image: url("../icon/convertible.svg");
  -webkit-mask-image: url("../icon/convertible.svg");
}

.icon-station-wagon {
  mask-image: url("../icon/station-wagon.svg");
  -webkit-mask-image: url("../icon/station-wagon.svg");
}

.icon-truck {
  mask-image: url("../icon/truck.svg");
  -webkit-mask-image: url("../icon/truck.svg");
}

.icon-rows {
  mask-image: url("../icon/rows.svg");
  -webkit-mask-image: url("../icon/rows.svg");
}

.icon-layout-grid {
  mask-image: url("../icon/layout-grid.svg");
  -webkit-mask-image: url("../icon/layout-grid.svg");
}

.icon-settings {
  mask-image: url("../icon/settings.svg");
  -webkit-mask-image: url("../icon/settings.svg");
}

.icon-drive {
  mask-image: url("../icon/drive.svg");
  -webkit-mask-image: url("../icon/drive.svg");
}

.icon-engine {
  mask-image: url("../icon/engine.svg");
  -webkit-mask-image: url("../icon/engine.svg");
}

.icon-transmission {
  mask-image: url("../icon/transmission.svg");
  -webkit-mask-image: url("../icon/transmission.svg");
}

.icon-palette {
  mask-image: url("../icon/palette.svg");
  -webkit-mask-image: url("../icon/palette.svg");
}

.icon-safe {
  mask-image: url("../icon/safe.svg");
  -webkit-mask-image: url("../icon/safe.svg");
}

.icon-route {
  mask-image: url("../icon/route.svg");
  -webkit-mask-image: url("../icon/route.svg");
}

.icon-percent {
  mask-image: url("../icon/percent.svg");
  -webkit-mask-image: url("../icon/percent.svg");
}

.icon-user-plus {
  mask-image: url("../icon/user-plus.svg");
  -webkit-mask-image: url("../icon/user-plus.svg");
}

.icon-signal {
  mask-image: url("../icon/signal.svg");
  -webkit-mask-image: url("../icon/signal.svg");
}

.icon-battery-charging {
  mask-image: url("../icon/battery-charging.svg");
  -webkit-mask-image: url("../icon/battery-charging.svg");
}

.icon-usb-flash-drive {
  mask-image: url("../icon/usb-flash-drive.svg");
  -webkit-mask-image: url("../icon/usb-flash-drive.svg");
}

.icon-car-cradle {
  mask-image: url("../icon/car-cradle.svg");
  -webkit-mask-image: url("../icon/car-cradle.svg");
}

.icon-baby-seat {
  mask-image: url("../icon/baby-seat.svg");
  -webkit-mask-image: url("../icon/baby-seat.svg");
}

.icon-booster {
  mask-image: url("../icon/booster.svg");
  -webkit-mask-image: url("../icon/booster.svg");
}

.icon-baby-seat-arm {
  mask-image: url("../icon/baby-seat-arm.svg");
  -webkit-mask-image: url("../icon/baby-seat-arm.svg");
}

.icon-paperclip {
  mask-image: url("../icon/paperclip.svg");
  -webkit-mask-image: url("../icon/paperclip.svg");
}

.icon-marker {
  mask-image: url("../icon/marker.svg");
  -webkit-mask-image: url("../icon/marker.svg");
}

.icon-coins {
  mask-image: url("../icon/coins.svg");
  -webkit-mask-image: url("../icon/coins.svg");
}

.icon-coins {
  mask-image: url("../icon/coins.svg");
  -webkit-mask-image: url("../icon/coins.svg");
}

.icon-state-number {
  mask-image: url("../icon/state-number.svg");
  -webkit-mask-image: url("../icon/state-number.svg");
}

.icon-x-close {
  mask-image: url("../icon/x-close.svg");
  -webkit-mask-image: url("../icon/x-close.svg");
}

.icon-line-chart-down {
  mask-image: url("../icon/line-chart-down.svg");
  -webkit-mask-image: url("../icon/line-chart-down.svg");
}

.icon-users {
  mask-image: url("../icon/users.svg");
  -webkit-mask-image: url("../icon/users.svg");
}

.icon-parking-rectangle {
  mask-image: url("../icon/parking-rectangle.svg");
  -webkit-mask-image: url("../icon/parking-rectangle.svg");
}

.icon-dots-horizontal {
  mask-image: url("../icon/dots-horizontal.svg");
  -webkit-mask-image: url("../icon/dots-horizontal.svg");
}

.icon-message-text-circle {
  mask-image: url("../icon/message-text-circle.svg");
  -webkit-mask-image: url("../icon/message-text-circle.svg");
}

.icon-filter-funnel {
  mask-image: url("../icon/filter-funnel.svg");
  -webkit-mask-image: url("../icon/filter-funnel.svg");
}

.icon-columns {
  mask-image: url("../icon/columns.svg");
  -webkit-mask-image: url("../icon/columns.svg");
}

.icon-switch-vertical {
  mask-image: url("../icon/switch-vertical.svg");
  -webkit-mask-image: url("../icon/switch-vertical.svg");
}

.icon-tram {
  mask-image: url("../icon/tram.svg");
  -webkit-mask-image: url("../icon/tram.svg");
}

.icon-luggage {
  mask-image: url("../icon/luggage.svg");
  -webkit-mask-image: url("../icon/luggage.svg");
}

.icon-arrow-up-right {
  mask-image: url("../icon/arrow-up-right.svg");
  -webkit-mask-image: url("../icon/arrow-up-right.svg");
}

.icon-arrow-down-left {
  mask-image: url("../icon/arrow-down-left.svg");
  -webkit-mask-image: url("../icon/arrow-down-left.svg");
}

.icon-info-octagon {
  mask-image: url("../icon/info-octagon.svg");
  -webkit-mask-image: url("../icon/info-octagon.svg");
}

.icon-save {
  mask-image: url("../icon/save.svg");
  -webkit-mask-image: url("../icon/save.svg");
}
.icon-calc-1 {
  mask-image: url("../icon/calc-1.svg");
  -webkit-mask-image: url("../icon/calc-1.svg");
}
