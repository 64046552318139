
.car-card-mobile {
    border-radius: inherit;
    overflow: hidden;
    padding: 5px 20px;
    margin-bottom: 15px;

  &__nav {
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row-reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 10px;

    .address__badge {
      width: 160px;
      padding: 7px 16px;
      background-color: var(--success-20);
      border-radius: 15px;
      color: var(--success-dark);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;

      &.warning {
        background-color: var(--warning-20);
        color: var(--warning-dark);
      }

      &.danger {
        background-color: var(--danger-10);
        color: var(--danger-dark);
      }
    }
  }

  &__ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    li {
      width: 48%;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;
      border: 1px solid var(--dark-15);
      border-radius: 10px;

      &:first-child {
        width: 100%;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      .rectangle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--danger);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
    }
  }

  &__footer {
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;

    &__right {
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;
    }
  }
}

