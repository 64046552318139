@import "../../asset/style/const.scss";

.modal-wrap {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 5;
    background-color: var(--dark-60);
    transition: 0.3s linear;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    visibility: hidden;
    opacity: 0.5;
}

.modal__main {
  max-width: 800px;
  width: 80%;
  background-color: var(--light);
  overflow-y: auto;
  position: absolute;
  right: -100%;
  height: 100%;
  transition: 0.3s linear;
  scrollbar-width: none;

  &.direction-right {
    right: -100%;
  }
  &.direction-left {
    left: -100%;
  }
  &.direction-bottom {
    bottom: -100%;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media(min-width: $tablet) {
    width: 66%;
  }
}

.modal__nav {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 30px;

  &.direction-left {
    flex-direction: row-reverse;

  }
  &.color-primary {
    background-color: var(--primary);
    
    div {
      background-color: var(--primary);
      
      i {
        background-color: var(--light);
      }
    }
  }

  h1 {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    color: var(--dark);
  }

  @media(min-width: $tablet) {
    padding: 30px 30px 0 60px;

    h1 {
        font-size: 26px; 
    }
  }
}

.modal-open.modal-wrap{
    opacity: 1;
    visibility: visible;

    .height-small {
      height: 300px;
      
      .modal__nav {
        padding: 10px;
        padding-left: 30px;

        h1 {
          font-size: 18px;
          font-weight: 400;
          color: var(--dark);
        }
      }
    }

    .height-big {
      height: 80vh;
    }

    .height-fill {
      height: 100vh;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .modal__nav.color-primary {
        padding: 0;
        padding-left: 30px;

        h1 {
          font-size: 18px;
          font-weight: 400;
          color: var(--light);
        }
      }
    }


    .modal__main {
      right: 0;

      &.direction-right {
        right: 0;
      }
      &.direction-left {
        left: 0;
      }
      &.direction-bottom {
        bottom: 0;
        width: 100%;

        @media (min-width: $tablet) {
          width: 90%;
          left: 5%;
        }
      }
    }
}
