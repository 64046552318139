@import "../../../../../asset/style/const.scss";


.month__item__container {
	margin-bottom: 20px;
}

.month__item__top {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 15px;
  border-bottom: 1px solid rgba(26, 43, 70, 0.08);

  @media(max-width: $desktop-sm) {
	flex-direction: column;
  }

  i {
    background-color: var(--dark);
  }

  &__title {
	display: flex;
	align-items: center;
	gap: 10px;
    font-size: 20px;
    p {
      font-size: 16px;
      color: var(--dark-50);
    }
  }

  &__cards {
    margin-left: auto;
    display: flex;
    gap: 10px;

		&__count {	
			border-radius: 16px;
			white-space: nowrap;
			text-align: center;
			padding: 8px;
			background-color: var(--primary-dark-5);

			& > h1 {
				font-size: 14px;
				font-weight: 600;
				line-height: 24px;
				letter-spacing: 0em;
				color: var(--dark);
			}

			& > p {
				color: var(--dark);
				font-size: 12px;
				font-weight: 400;
				line-height: 17px;
				letter-spacing: 0em;
				opacity: 0.6;
			}
		}

		&__count.count--current {
			background-color: var(--light);
			border: 1px solid var(--primary-dark-15);

			& > h1 {
				font-size: 18px;
			}

			& > p {
				font-size: 14px;
			}
		}
		&__count.count--pay {
			background-color: var(--light);

			& > h1 {
				font-size: 22px;
				color: var(--dark);
			}

			& > .pay-warning {
				color: var(--warning-dark);
			}
			& > .pay-success {
				color: var(--success);
			}

			& > p {
				font-size: 14px;
			}
		}
  }
}

.month__item__footer {
	padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: $desktop-sm) {
	display: block;
	.report__button {
		margin-bottom: 20px;
	}
  }

	.report__button {
		display: flex;
		gap: 10px;
		align-items: center;
		white-space: nowrap;
		text-align: center;
		padding: 8px 15px;
		background-color: var(--light);
		border-radius: 16px;
		border: 1px solid var(--primary-dark-15);
		cursor: pointer;

		p {
			font-size: 14px;
		}

		p:last-child {
			font-size: 12px;
			opacity: 0.5;
		}
	}

	.info-report {
		white-space: nowrap;
		text-align: end;
		padding: 8px 15px;

		p {
			font-size: 14px;
		}
		p:last-child {
			font-size: 12px;
			opacity: 0.5;
		}
	}

    &__btn-wrap {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}