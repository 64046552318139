@import "../../../../asset/style/const.scss";

.booking-information {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 5px;
    height: max-content;
    position: relative;

    .choose-car {
        position: sticky;
        top: 60px;
        z-index: 3;
        text-align: center;
        background: var(--light);
        box-shadow: 0px 2px 10px var(--dark-5);
        border-radius: 20px;
        cursor: pointer;
        
        p {  
            margin: 10px;
            padding: 20px;
            color: var(--primary);
            border: 1px dashed var(--primary-60);
            border-radius: 12px;
        }
    }
}

.booking-information {
  &__top {
    padding-top: 16px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 5px;

    .title {
        display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				gap: 5px;
        margin-right: auto;

      &__status {
        display: inline-block;
        margin-left: 15px;
        position: relative;
        top: 5px;

        .icon {
          background-color: var(--success);
        }
      }
    }

    .title-right {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 10px;
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0em;
      color: var(--dark);
			margin-bottom: 0;
    }

    .rework {
      display: flex;
      align-items: center;
      gap: 10px;
    }

		.button-wrap {
			display: flex;
			align-items: center;
			gap: 10px;
		}
  }

  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;

		.contractor-name {
			padding: 8px 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 20px;

      p {
        width: 200px;
      }

      div {
        width: 100%;
      }
		}

    .control-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  &__option {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    > div {
      height: fit-content;
    }
  }
}