@import "../../../../../asset/style/const.scss";

.req-create-left {
  grid-row: 1/3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 5px;
  height: max-content;
  position: relative;

  .choose-car {
    position: sticky;
    top: 60px;
    z-index: 3;
    text-align: center;
    background: var(--light);
    box-shadow: 0px 2px 10px var(--dark-5);
    border-radius: 20px;
    cursor: pointer;

    p {
      margin: 10px;
      padding: 20px;
      color: var(--primary);
      border: 1px dashed var(--primary-60);
      border-radius: 12px;
    }
  }
}

.req_create_left {
  &__top {
    padding: 16px 20px 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 15px;

    h1 {
      font-size: 26px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      color: var(--dark);
    }

    & > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;

      .view_settings__wrap {
        position: relative;

        & > ul {
          position: absolute;
          bottom: -5px;
          right: -5px;
          transform: translateY(100%);
          z-index: 4;
          background-color: var(--light);
          border: 1px solid var(--primary-dark-15);
          box-shadow: 0px 0px 20px var(--secondary-dark-10);
          border-radius: 16px;
          padding: 10px 10px 10px 20px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          gap: 10px;

          li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            gap: 25px;

            & > p {
              font-size: 13px;
              font-weight: 400;
              line-height: 17px;
              letter-spacing: 0em;
              color: var(--dark);
              white-space: nowrap;
            }

            & > div {
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }

          &::after {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: var(--light);
            border: 1px solid var(--primary-dark-15);
            top: -5px;
            right: 20px;
            transform: rotate(45deg);
            border-right: none;
            border-bottom: none;
          }
        }
      }
    }
  }

  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
