@import "../../asset/style/const.scss";

.black__client__modal {
margin-bottom: 20px;
border: 1px solid var(--danger);
border-radius: 30px;
}

.black__client__modal__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  padding: 15px;

  h1 {
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    color: var(--dark);
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--dark-70);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;

    i {
      background-color: var(--dark-20);
    }
  }

  @media (min-width: $desktop-sm) {
    gap: 30px;
    padding: 30px;

    h1 {
      font-size: 22px; 
    }
    p {
      font-size: 16px;
    }
  }
}