@import "../../asset/style/const.scss";

.req__success__card {
    &__main {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;
			gap: 30px;
			padding: 20px;

			@media (min-width: $desktop-sm) {
				min-height: 584px;
				padding: 60px 30px 30px;
			}
    }

    &__top {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;

      h1 {
        font-size: 22px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        color: var(--dark);
        margin: 30px 0 5px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        opacity: 0.7;
      }
    }

    &__bottom {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 10px;

      .price__wrap {
        border: 1px solid var(--success-dark);
        border-radius: 24px;
        padding: 10px 20px;
        font-size: 22px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0em;
        color: var(--success-dark);

        &.warning {
          border-color: var(--warning-dark);
          color: var(--warning-dark);
        }
      }

      & > p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        opacity: 0.4;
      }
    }
  }