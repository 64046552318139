@import "../../../../../asset/style/const.scss";

.filter-nav {
    background: var(--light);
    box-shadow: 0px 0px 20px var(--secondary-dark-10);
    position: sticky;
    position: -webkit-sticky;
    width: 100%;
    top: 0;
    left: 0;
    transition: 0.2s;
    z-index: 3;


  &__top {
    padding: 6px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;

    }

  &__select {
    box-shadow: none;
    &--category,
		&--location {
			width: 50%;
			flex-grow: 2;
		}
  }

	@media (min-width: $desktop-sm) {
		&__top { 
			padding: 10px;
			display: -ms-grid;
			display: grid;
			grid-template-columns: 40px repeat(3, 1fr) 130px;
			gap: 5px;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
		}

    &__select {
      box-shadow: none;
      &--category,
      &--location {
        width: 100%;
        flex-grow: 2;
      }
    }
	}
}