@import "../../../../../asset/style/const.scss";


.total-price {
    height: auto;
}
.total-price__main {
    position: fixed;
    bottom: 0;
    z-index: 4;
    width: 100%;
    height: auto;
    padding: 15px 20px 20px;
    padding-bottom: 30px;
    background: var(--light);
    box-shadow: 0px 2px 10px var(--dark-50);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    @media (min-width: $tablet) {
        width: 90%;
    }

    @media (min-width: $desktop-sm) {
        position: static;
        width: auto;
        padding: 15px;
        border-radius: 20px;
        box-shadow: none;
    }
}

.mobile-total-price {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 10px;

    .price-block {
        width: 30%;
        text-align: center;
        padding: 7px;
        border: 1px solid rgba(26, 43, 70, 0.15);
        border-radius: 8px;

        &__number {
            font-size: 14px;
            font-weight: 600;
        }

        &__text {
            font-size: 12px;
            color: var(--dark-50);
        }

        &__text--btn {
            cursor: pointer;
        }
    }

}

.modal-discount__btn {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 15px;
    font-size: 18px;
    color: var(--light);
    text-align: center;
    border-radius: 12px;
    background: var(--primary);
}

.total-price__total {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    color: var(--dark);
    border-bottom: 1px solid lightgray;

    @media (min-width: $desktop-sm) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .price-with-discount {
      display: flex;

      &__line-through {
        margin-right: 15px;
        opacity: 0.6;
        text-decoration: line-through;
      }

      .sum-price {
        text-align: center;
      }
    }

    .price-per-day {
      font-size: 13px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0em;
      opacity: 0.6;
    }
}


.total-price__list {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
    align-items: baseline;
    margin-top: 10px;
    margin-bottom: 10px;

    h1 {
      display: inline-block;
      margin-right: 10px;
      font-size: 17px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark);
    }

    .discount {
      background-color: var(--primary-10);
      color: var(--primary);
      padding: 4px 8px 4px 10px;
      border-radius: 25px;
      font-size: 15px;
      font-weight: 500;
      line-height: 15px;
    }

}
.input-discount {
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--primary-dark-15);
  border-radius: 8px;

  @media (min-width: $desktop-sm) {
    margin-left: 0;
    margin-right: 0;
    padding: 5px;
  }

  h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--dark);

    input {
      width: 35px;
      font-size: 16px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0em;
      color: var(--dark);
      border: none;
      text-align: center;

      &:focus {
        outline: none;
      }
    }
  }

  p {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    color: var(--dark);
    opacity: 0.5;
  }
}

.total-price__booking {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (min-width: $desktop-sm) {
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
    }


    h1 {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      color: var(--dark);
    }
}

.total-price__footer {
    display: flex;
    gap: 20px;
    align-items: center;
}

.calculation__footer-wrap {
  margin-top: 10px;
}
.calculation__footer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}