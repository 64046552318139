.control-info {
    width: 50%;
    margin: 15px;
		margin-right: 0;
		margin-bottom: 8px;
    padding-right: 20px;
    border-right: 1px solid gray;

    .label-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
				
				span {
					display: inline-block;
					font-size: 10px;
					color: var(--dark-50);
					margin-bottom: 10px;
			}
    }

    &__main-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        gap: 10px;

        &__input {
          flex-grow: 2;
        }
      
    }

		&__block {
			margin-bottom: 15px;

		}

    &__mark-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    &__mark {
      font-size: 12px;
      font-weight: 400;
      color: var(--primary);
      background-color: var(--primary-10);
      padding: 8px 14px;
      border-radius: 16px;
    }

    &__add-drivers {
      display: inline-block;

      .icon {
        background-color: var(--light);
      }
    }

    .block-checkbox {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;

        .checkbox {
            display: flex;
            gap: 5px;
            align-items: center;
            font-size: 12px;
        }
    }

}