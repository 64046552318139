@import "../../../../../asset/style/const.scss";

.calc-price {
  &__main {
    // @media (min-width: $desktop-sm) {
      overflow: hidden;
      border-radius: 20px;
      background: var(--light);
      box-shadow: 0px 2px 10px var(--dark-5);
      position: relative;
    // }
  }

  &__top {
    padding: 15px 10px 0 10px;
    margin-bottom: 10px;

    .booking-title {
        display: block;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        padding-left: 10px;
        margin-bottom: 15px;
    }

    .req-create-title {
      display: none;

      @media (min-width: $desktop-sm) {
        display: block;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        padding-left: 10px;
        margin-bottom: 15px;
      }
    }
  }

  &__list {
    overflow-y: auto;
    max-height: calc(100vh - 808px);
    padding-bottom: 14px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--dark-15);
      border-radius: 2px;
    }

    @media (max-height: 1080px) {
      max-height: calc(1080px - 714px);
    }
  }
  .calc-price__show-scripts &__list {
    max-height: calc(100vh - 565px);

    @media (max-height: 1080px) {
      max-height: calc(1080px - 563px);
    }
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary-dark-15);

    .item__left {
      padding: 6px 14px 6px 10px;

      h1 {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 210px;
      }

      p {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        color: var(--dark);
        opacity: 0.5;
      }
    }

    .promo-percent {
      color: var(--light);
      background-color: var(--danger);
      position: absolute;
      top: 8px;
      left: 70%;
      padding: 4px 8px;
      border-radius: 16px;
      transform: translateX(-50%);
    }

    .item__right {
      background-color: var(--primary-dark-3);
      padding: 6px 16px;
      text-align: end;
      white-space: nowrap;

      h1 {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        color: var(--dark);
        opacity: 0.6;
        margin-bottom: 2px;
      }

      p {
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--dark);
      }
    }
  }
}
