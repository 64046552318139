@import "../../../../../asset/style/const.scss";

.rent-tariff {
  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 16px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  

    &__list {
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      position: relative;
      width: 100%;

      li {
        width: 100%;
        border: 1px solid var(--primary-dark-15);
        border-radius: 12px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        flex-direction: column-reverse;
        padding: 8px 15px;
        cursor: pointer;
        transition: 0.2s linear;
        gap: 10px;

        @media (min-width: $tablet) {
          flex-direction: row;
        }

        h1 {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark);
          opacity: 0.6;
          margin-bottom: 2px;
          transition: 0.2s linear;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
          transition: 0.2s linear;
        }

        i {
          background-color: var(--dark);
          opacity: 0.2;
          align-self: end;
        }

        &.active {
          background-color: var(--primary);
          border-color: transparent;

          h1,
          p {
            color: var(--light);
          }

          i {
            background-color: var(--light);
            opacity: 1;
          }
        }
      }
    }
  }