@import "../../asset/style/const.scss";

.req__info__card {
    background-color: var(--light);
    box-shadow: 0px 2px 10px var(--dark-5);
    border-radius: 20px;
    padding: 30px 20px 20px 20px;
    margin-bottom: 10px;

  &__top {
    padding: 0 15px 24px 15px;
    border-bottom: 1px solid var(--primary-dark-15);

    ul {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 20px;

      @media(min-width: $desktop-sm) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;

        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          margin-bottom: 2px;
          color: var(--dark);
          opacity: 0.7;
        }

        h1 {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          color: var(--dark);
        }
      }
    }
  }

  &__body {
    position: relative;
    padding: 30px 20px;

    .rent__period_display__wrap {
      position: absolute;
      top: 20px;
      right: 0px;
      background: var(--dark-5);
      border-radius: 12px;
      padding: 10px;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0em;
      color: var(--dark);

      span {
        font-weight: 600;
      }
    }

    & > ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 15px;

      li {
        h1 {
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          margin-bottom: 5px;
          color: var(--dark);
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
          opacity: 0.7;
        }

        &.car__info {
          & > div {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 10px;
          }
        }

        &.add__options {
          h1 {
            margin-bottom: 10px;
          }

          & > ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            gap: 10px;
          }
        }

        &.tarif__date {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 40px;
        }
      }

      @media (min-width: $desktop-sm) {
        gap: 30px;

        li{
            h1{
                font-size: 20px;
            }
            p {
                font-size: 16px;
            }
        }
      }
    }
  }

  &__edit {
    position: absolute;
    top: 30px;
    right: 10px;
  }

  &__bottom {
      display: flex;
      flex-direction: column;
      gap: 10px;

    @media( min-width: $desktop-sm) {
        display: -ms-grid;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
  }
}

.empty_display {
  width: max-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;

  .warning_circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--warning);
  }
}

.footer-card {
    border: 1px solid var(--primary-dark-15);
    border-radius: 20px;
  
    & > nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;
      padding: 20px;
  
      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        color: var(--primary);
      }
    }
  
    ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 20px;
      padding: 0px 15px 30px 63px;
  
      li {
        h2 {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
          margin-bottom: 5px;
        }
  
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
          opacity: 0.7;
        }
      }
    }   
}