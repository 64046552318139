.select-wrap {
    position: relative;
    width: 100%;
}

.select {
  &__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: var(--light);
    border: 1px solid var(--primary-dark-15);
    border-radius: 12px;
    padding: 8px 10px 8px 20px;
    cursor: pointer;
    gap: 10px;


    h1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      color: var(--dark);
      opacity: 0.3;

      &.active {
        opacity: 1;
      }
    }

    &.defaultName h1 {
      opacity: 1;
    }

    i {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      background-color: var(--dark-30);
      transition: none;
    }
  }

  &__list__wrap {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    transform: translateY(99%);
    z-index: 3;
    background-color: var(--light);
    overflow: hidden;
    border: 1px solid var(--primary-dark-15);
    border-radius: 16px;
  }

  &__list__wrap.top {
    transform: translateY(-42px);
  }

  &__list {
    overflow-y: auto;
    min-height: 50px;
    max-height: 300px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--dark-15);
      border-radius: 2px;
    }

    li {
      padding: 16px 30px;
      cursor: pointer;
      transition: 0.2s linear;

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark);
        transition: 0.2s linear;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        background-color: var(--dark-5);
      }

      &.active {
        background-color: var(--primary-10);

        p {
          color: var(--primary);
        }
      }
    }
  }

    .openSelect
          &__nav {
            padding: 8px 10px 8px 20px;

            h1 {
              font-size: 14px;
              font-weight: 400;
              line-height: 17px;
            }

            i {
              width: 20px;
              height: 20px;
              min-width: 20px;
              min-height: 20px;
            }
          }

          &__list__wrap .isScroll {
            padding-right: 2px;
          }

          &__list {
            min-height: 41px;

            &::-webkit-scrollbar {
              width: 3px;
            }

            li {
              padding: 12px 24px;
              gap: 8px;

              p {
                font-size: 14px;
                line-height: 17px;
              }
            }
          }
}


  .disabled .select__nav {
    cursor: default;
    background-color: var(--dark-5);
  }