@import "../../../asset/style/const.scss";

.hour__picker {
  position: relative;

  &__nav {
    border: 2px solid var(--primary-dark-15);
    border-radius: 20px;
    padding: 20px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
    cursor: pointer;

    h1 {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      color: var(--dark-70);
    }

    .time__display {
      display: flex;
      align-items: center;
      gap: 5px;

      font-size: 30px;
      font-weight: 500;
      line-height: 37px;
      letter-spacing: 0em;
      text-align: center;

      input {
        width: 45px;
        font-size: 30px;
        font-weight: 500;
        line-height: 37px;
        letter-spacing: 0em;
        text-align: center;
        border: none;

        &:focus {
          outline: none;
        }
      }
    }

		&.hour__picker__nav--open {
			border: 2px solid var(--primary);
		}
	}

  &__main {
    position: absolute;
    background: var(--light);
    border: 1px solid var(--primary-dark-15);
    box-shadow: 0px 0px 20px var(--secondary-dark-10);
    border-radius: 30px;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 100%);
    z-index: 4;

    &::before {
      content: "";
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translateX(-50%) rotate(-225deg);
      width: 10px;
      height: 10px;
      background-color: var(--light);
      border-width: 0px 0px 1px 1px;
      border-style: solid;
      border-color: var(--primary-dark-15);
    }

    &__left,
    &__right {
      max-height: 223px;
      overflow-y: auto;
      padding-right: 12px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--light);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--dark-15);
        border-radius: 2px;
      }
    }

    &__left {
      margin-right: 4px;
    }

    &__right {
      padding-left: 20px;
      border-left: 1px solid var(--primary-dark-15);
    }
  }

  &__hour,
  &__min {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 5px;

    li {
      text-align: center;
      width: 125px;
      padding: 16px;
      border-radius: 14px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark);
      cursor: pointer;
      transition: 0.2s linear;

      &:hover {
        background-color: var(--dark-3);
      }

      &.active {
        background-color: var(--primary-10);
        color: var(--primary);
      }
    }
  }
}

@media (max-width: $desktop-sm) {
	.hour__picker {
		&__nav {
			padding: 10px;
			gap: 8px;

			h1 {
				font-size: 14px;
			}
	
			.time__display {
				display: flex;
				align-items: center;
				gap: 5px;
	
				font-size: 18px;
				font-weight: 500;
				line-height: 37px;
				letter-spacing: 0em;
				text-align: center;
	
				input {
					width: 45px;
					font-size: 30px;
					font-weight: 500;
					line-height: 37px;
					letter-spacing: 0em;
					text-align: center;
					border: none;
	
					&:focus {
						outline: none;
					}
				}
			}
		}

		&__main {
			padding: 15px 8px;

      &__left {
        padding-right: 0;
      }

      &__right {
        padding-left: 5px;
      }
		}

		&__hour,
  	&__min {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			gap: 5px;

			li {
				text-align: center;
				width: 70px;
				padding: 8px;
				border-radius: 14px;
				font-size: 16px;
				font-weight: 400;
				line-height: 16px;
			}
		}

	}
}