.searching-select {
	position: relative;

		.icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 10px;
			background-color: var(--dark-15);
			cursor: inherit;
		}
    
    &__values {
      display: none;
			overflow-y: auto;
			min-height: 50px;
			max-height: 300px;
	
			&::-webkit-scrollbar {
				width: 4px;
			}
	
			&::-webkit-scrollbar-thumb {
				background: var(--dark-15);
				border-radius: 2px;
			}
	
			li {
				padding: 14px 25px;
				cursor: pointer;
				transition: 0.2s linear;
	
				p {
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0em;
					color: var(--dark);
					transition: 0.2s linear;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
	
				&:hover {
					background-color: var(--dark-5);
				}
	
				&.active {
					background-color: var(--primary-10);
	
					p {
						color: var(--primary);
					}
				}
    	}
		}
    
    &__search {
			position: relative;
			width: 100%;
			background: var(--light);
			border: 1px solid var(--primary-dark-15);
			border-radius: 12px;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: 0em;
			color: var(--dark);
			padding: 8px 10px;
			transition: 0.2s linear;
		
				
			&::placeholder {
				color: var(--dark-40);
				font-weight: 400;
			}

			&.disabled {
				background: var(--dark-5);
				border: 1px solid var(--primary-dark-15);
				color: var(--dark-40);
			}

			&:focus {
				outline: none;
				border: 1px solid var(--primary);
			}
		}

		input[type="search"]::-webkit-search-cancel-button {
			-webkit-appearance: none;
			position: relative;
			right: -10px;
			width: 12px;
			height: 12px;
			color: var(--dark-40);
			background-color: var(--light);
			background: url("../../asset/icon/close.svg") no-repeat center center;
	}
	

	&__values.open {
			display: block;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			background: var(--light);
			border: 1px solid var(--primary-dark-15);
			border-radius: 14px;
			transition: 0.2s linear;
			z-index: 1;
		}

}