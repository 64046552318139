  @import "../../asset/style/const.scss";

	.pay__card {
		background-color: var(--success-15);
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		border-radius: 20px;
		overflow: hidden;
		border: 0px solid var(--success);

    &__icon {
      background-color: var(--success);
      border-radius: 20px;
      padding: 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    &__info {
      padding: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 5px;

      h1 {
        font-size: 30px;
        font-weight: 600;
        line-height: 37px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        opacity: 0.7;
      }
    }
  }

	.pay__card.color--warning {
          background-color: var(--warning-10);
          border-color: var(--warning);

          .pay__card {
            &__icon {
              background-color: var(--warning);
            }
          }
				}

	.pay__card.color--primary {
          background-color: var(--primary-15);
          border-color: var(--primary);

          .pay__card {
            &__icon {
              background-color: var(--primary);
            }
          }
	}

	.pay__card.color--primary.border {
		border-width: 1px;
		background-color: var(--light);
		padding: 10px;
	
		.pay__card {
			&__icon {
				background-color: var(--primary-10);
		
				i {
					background-color: var(--primary);
				}
			}
	
			&__info {
				padding: 20px;
			}
		}
	}

	@media (max-width: $desktop-sm) {
		.pay__card {
			margin-bottom: 10px;

			&__icon {
				padding: 10px;
			}

			&__info {
				padding: 15px;

				h1 {
					font-size: 22px;
					line-height: 18px;
				}
	
				p {
					font-size: 14px;
					line-height: 16px;
				}
			}
		}
	}
