@import "../../asset/style/const.scss";

.catalog-rent-term {

    background: var(--light);
    // box-shadow: 0px 2px 5px var(--primary-dark-5);
    border-radius: 16px;
    
    & > ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
      gap: 10px;
      padding: 10px;
    
      li {
				width: 48%;
        text-align: center;
        padding: 8px 20px;
        border: 1px solid var(--primary-dark-15);
        border-radius: 14px;
    
        h1 {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark);
          opacity: 0.5;
        }
    
        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
        }
    
        &.active {
          position: relative;
    
          &::after {
            content: '';
            position: absolute;
            width: 101%;
            height: 101%;
            top: 50%;
            left: 0px;
            transform: translate(0%, -50%);
            border: 3px solid var(--success-50);
            border-radius: 14px;
          }
        }
      }

      &.term-big-size {
        & > ul {
          li {
            h1 {
              font-size: 14px;
            }
            p {
              font-size: 16px;
            }
  
          }
        }
      }
    }

    @media (min-width: $desktop-sm) {
        & > ul {
            display: -ms-grid;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            padding: 10px;
          
            li {
							width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              gap: 10px;
              padding: 12px;
              border: none;
							border-radius: 0px;
              border-left: 1px solid var(--primary-dark-15);
        
              &:first-of-type {
                border-left: none;
              }

							&.active {
								position: relative;
					
								&::after {
									content: '';
									position: absolute;
									width: 90%;
									height: 90%;
									top: 50%;
									left: 7px;
									transform: translate(0%, -50%);
									border: 3px solid var(--success-50);
									border-radius: 14px;
								}
							}
            }
          }
    }
}