@import "../../../../../asset/style/const.scss";

.req-create-middle__mobile {

    grid-column: 2/3;
    grid-row: 1/4;
    position: relative;
    width: 100%;
    height: calc(174vh - 80px);
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px 2px 5px var(--primary-dark-15);
    background-color: var(--light);
    
    // @media (min-width: 1600px) { 
    //   height: calc(100vh - 60px);
    // }
    
    
    .car__list--with-filter {
      display: flex;
    }
    
    .car__list {
      position: relative;
      background-color: var(--light);
      height: 160vh;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
    
      .working_filters_display {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 10px;
      }
    
      &::-webkit-scrollbar {
        width: 4px;
      }
    
      &::-webkit-scrollbar-thumb {
        background: var(--dark-15);
        border-radius: 2px;
      }
    
      .agent__template {
        padding: 100px 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;
    
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: center;
          color: var(--dark);
        }
      }
    
      &__top {
        position: sticky;
        top: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 10px;
        padding-left: 20px;
        padding-bottom: 10px;
        background-color: var(--light);
        z-index: 2;
        box-shadow: 0px 2px 5px var(--primary-dark-15);

    
        &__left {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 10px;
    
          h1 {
            font-size: 22px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0em;
            color: var(--dark);
          }
    
          p {
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
            color: var(--dark);
            opacity: 0.7;
          }
        }
    
        &__right {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 10px;
    
          p {
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
            color: var(--dark);
            opacity: 0.5;
          }
        }
    
        .sort__act__wrap {
          display: flex;
          align-items: center;
          gap: 15px;
    
          .sort__wrap {
            display: flex;
            gap: 5px;
          }
        }

        .filter-mobile__wrap {
          padding: 10px;
          border: 1px solid var(--primary-dark-15);
          border-radius: 12px;
          cursor: pointer;
        }
      }
    
      & > ul {
        padding: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 5px;
      }
    
      .exist-car {
        height: calc(172vh - 340px);

        @media (min-width: $desktop-md) { 
            height: 65vh;
        }
      }
    
    
    }
}

.modal-refresh {
  padding-left: 20px;

  p {
    margin-bottom: 15px;
    font-size: 22px;
    color: var(--dark-50);
  }
}
