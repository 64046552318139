.modal-driver-wrapper {
    padding: 20px;
}

.modal-driver {
    padding: 20px 30px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    h2 {
        font-size: 18px;
        font-weight: 500;
    }

    &__select {
        flex-grow: 3;
    }

    &__btn {
        margin: auto;
        margin-top: 30px;
    }
}
