@import "../../../../../asset/style/const.scss";


.client-info {
    &__main {
      padding: 20px;
      margin-bottom: 200px;

    @media (min-width: $desktop-sm) {
				padding: 10px;
        margin-bottom: 0;
			}

      & > h1 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        padding-left: 10px;
        margin-bottom: 15px;
      }
    }

    &__form {
      margin: 15px 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 5px;
    }

    &__document {
      &__top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        & > h1 {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
          padding-left: 10px;
        }

        .icon__wrap {
          height: 30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          i {
            background-color: var(--dark);
            opacity: 0.2;
          }
        }
      }

      &__list {
        display: -ms-grid;
        display: grid;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        grid-template-columns: repeat(6, 45px);
        gap: 6px;
        margin-top: 10px;

        .upload__wrap {
          border: 1px dashed var(--primary-20);
          border-radius: 12px;
          height: 45px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          cursor: pointer;

          i {
            background-color: var(--primary);
          }
        }

        .image__wrap {
          height: 45px;
          border-radius: 12px;
          overflow: hidden;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .trash__wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--light-50);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            visibility: hidden;
            opacity: 0;
            transition: 0.4s;

            i {
              background-color: var(--danger);
            }
          }

          &:hover {
            .trash__wrapper {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }