@import "../../../../asset/style/const.scss";

.draft-req-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: var(--dark-60);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.draft_req_modal {
  &__main {
    position: relative;
    background: var(--bg);
    overflow: hidden;
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__top-mobile {
		position: fixed;
		z-index: 5;
		width: 100vw;
    padding: 20px;
    padding-right: 10px;
		display: flex;
		justify-content: space-between;
    background-color: var(--primary);
    box-shadow: 0px 2px 5px var(--dark-5);
    gap: 30px;

		& .icon {
			background-color: var(--light);
		}

		h1 {
			font-size: 18px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0em;
			color: var(--light);
			margin-bottom: 5px;
		}

		p {
			font-size: 12px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0em;
			color: var(--light);
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			gap: 5px;
		}
	}

  &__top {
    background-color: var(--light);
    box-shadow: 0px 2px 5px var(--dark-5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px;
    padding-left: 30px;
    gap: 30px;

    &__left {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 30px;

      & > i {
        background-color: var(--primary-dark-15);
      }

      h1 {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--dark-60);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 5px;

        i {
          background-color: var(--dark-60);
        }
      }
    }

    &__right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;

      .date_num_info {
        background-color: var(--dark-3);
        border-radius: 14px;
        padding: 8px 20px;

        h1 {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
          margin-bottom: 2px;
        }

        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark);
          opacity: 0.5;
        }
      }

      .status_info {
        border: 1px solid var(--primary-dark-15);
        border-radius: 14px;
        padding: 8px 20px;

        h1 {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
          margin-bottom: 2px;
        }

        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark);
          opacity: 0.7;
        }
      }
    }
  }

  &__body {
		margin-top: 95px;

    .save_info__card {
      background: var(--light);
      box-shadow: 0px 2px 5px var(--primary-dark-5);
      border-radius: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 20px 20px 20px 30px;
			margin-bottom: 10px;

      &__left {
        h1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
          margin-bottom: 5px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
          opacity: 0.7;
        }
      }

      &__right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;
      }
    }
  }
}



@media (min-width: $desktop-sm) {
    .draft_req_modal {
        &__main {
          position: relative;
          background: var(--bg);
          box-shadow: 0px 0px 20px var(--secondary-dark-10);
          border-radius: 20px;
          overflow: hidden;
          width: 1240px;
          max-height: 95vh;
          overflow-y: auto;
      
          &::-webkit-scrollbar {
            width: 0;
          }
        }
    }

		.draft_req_modal__body {
			margin-top: 0;
			padding: 20px;
			display: grid;
			display: -ms-grid;
			grid-template-columns: 1fr 2fr;
			gap: 20px;
		}
}