@import "../../asset/style/const.scss";

.car__term {
    background: var(--light);
    box-shadow: 0px 2px 10px var(--dark-5);
    height: 100%;
    position: relative;
    border-radius: 20px;
    padding: 20px 30px;
    &__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;

    h1 {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark);
    }

    a {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--primary);
    }
  }

  &__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 10px;
    padding: 0 30px;

    li {
      text-align: center;

      h1 {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark-60);
        margin-bottom: 5px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }
    }

    @media (max-width: $desktop-sm) {
      padding: 20px;
			padding-top: 0;
      flex-direction: column;
      
      li {
				display: flex;
        justify-content: space-between;
				padding-top: 20px;
				padding-bottom: 20px;
        width: 100%;

        border-bottom: 1px solid rgba(26, 43, 70, 0.08);

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

	&__link-mobile {
		display: inline-block;
		width: 90%;
		text-align: right;
	}

  @media (max-width: $desktop-sm) {
    box-shadow: none;
  }
}
