.agent-info {
    padding: 10px 15px;
    padding-bottom: 4px;

    &__form {
      margin-bottom: 10px;
  

    &__status {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 15px;
      margin-top: 10px;
      margin-bottom: 10px;

      h2 {
        font-size: 14px;
        font-weight: 600;
        color: var(--dark);
      }

      .tab-wrapper {
        background-color: var(--dark-10);
        border-radius: 12px;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .tab-status {
        margin: 5px;
        padding: 10px 20px;
        background-color: var(--light);
        border-radius: 12px;
        cursor: pointer;
      }

      .tab-status.active {
        background-color: var(--primary);
        color: var(--light);
      }
    }
  }
}