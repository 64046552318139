@import "../../../../../asset/style/const.scss";

.client__top {
	&__wrap {
		margin-bottom: 10px;
	}

	&__content {
    padding: 20px;
		display: flex;
		flex-direction: column-reverse;
		gap: 20px;
	}

	&__left, &__right {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
	}

	@media (min-width: $desktop-sm) {
    &__content {
      flex-direction: row;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 20px;
    }
  }
}