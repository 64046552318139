@import "../../../../../asset/style/const.scss";

.client__list {
    &__content {
        min-height: 500px;
    }
    
    &__nav {
    padding: 20px;
    padding-bottom: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 30px;
      min-height: 50px;
    }

    &__title {
      padding-left: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;

      h1 {
        font-size: 38px;
        font-weight: 600;
        line-height: 46px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark-70);
      }
    }

    &__search {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: end;
      gap: 10px;
      width: 50%;

			div:nth-child(2) {
				width: 60%;
			}
        
    }

    &__main {
      margin-top: 30px;

      &__ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 0px;

        > li {
            padding: 20px;
            border-bottom: 1px solid var(--dark-10);
        }
      }
			
			@media (min-width: $desktop-sm) {
				&__ul {
					padding: 20px;
					gap: 10px;
	
					> li {
							padding: 0;
							border-radius: 14px;
							border: 1px solid transparent;
					}

					> li:nth-child(2n) {
						background-color: var(--dark-3);
					}

					> li:hover {
						border: 1px solid var(--dark-10);
					}
				}
			}
    }
  }