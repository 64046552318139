.overrun-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 8px 10px;

  .checkbox__wrap {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .overrun {
    display: flex;
    align-items: center;
    gap: 10px;
		width: 33%;
  }

	.destination {
    display: flex;
    align-items: center;
    gap: 10px;
		flex-grow: 2;

		p {
			white-space: nowrap;
		}
  }
}