@import "../../asset/style/const.scss";

.req__list__item {
  &__header {
    position: relative;
		padding: 20px 15px 15px;
    display: flex;
    flex-direction: column;
		gap: 12px;
		
		&__left {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: start;
			gap: 20px;
			
			.req__infos {
        h1 {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          color: var(--dark);
        }
				
        p {
					font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark);
          opacity: 0.6;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 5px;
          margin-top: 5px;
					
          i {
            background-color: var(--dark);
          }
        }
      }
    }
		
    &__right {
			display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
			justify-content: end;
      gap: 10px;
			
      .req__income__wrap {
        position: absolute;
        top: 30px;
				padding: 0 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--success-dark);
				
        &.text-warning-dark {
					color: var(--warning-dark);
        }
      }
			
      .req__sum__wrap {
				background-color: var(--dark-3);
        border-radius: 14px;
        padding: 8px 20px;
        text-align: center;
				
        h1 {
					font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
          margin-bottom: 2px;
        }
				
        p {
					font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark-60);
        }
      }

      .req__date__wrap {
				background-color: var(--dark-3);
        padding: 8px;
        border-radius: 14px;
        text-align: center;
        width: 165px;
				
        h1 {
					font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          margin-bottom: 2px;
          color: var(--dark);
        }
				
        p {
					font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark);
          opacity: 0.5;
        }
      }
			
      .req__status__wrap {
				border: 1px solid var(--primary-dark-15);
        border-radius: 14px;
        padding: 8px 10px;
        min-width: 172px;
        position: relative;
        text-align: center;
				
        h1 {
					font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
          margin-bottom: 2px;
        }
				
        p {
					font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark);
          opacity: 0.7;
        }
				
        i {
					position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
					
          &.icon-check-circle {
						background-color: var(--success);
          }
					
          &.icon-slash-circle {
						background-color: var(--danger);
          }
        }
      }

			.req__agent__extended {
				position: absolute;
				top: 10px;
				right: 14px;

				@media (min-width: $tablet) {
					position: relative;
					top: 0;
					right: 0;
				}
			}
			
      i.icon-chevron-down {
				background-color: var(--dark);
        margin: 0 12px 0 22px;
      }
    }
  }
	
  &__body {
		background-color: var(--bg);
  }
}

@media(min-width: $desktop-sm) {
	.req__list__item {
		border-radius: 20px;
		border: 2px solid transparent;
		overflow: hidden;

		&__header {
			flex-direction: row;
			justify-content: space-between;
			transform: translateY(0.001%);
			border-radius: 0;
			border: 2px solid transparent;
			box-shadow: 0px 8px 10px var(--dark-5);
			position: relative;
			z-index: 1;

      &__right {
        .req__income__wrap {
          position: relative;
          top: 0;
          padding: 0 20px;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--success-dark);
          
          &.text-warning-dark {
            color: var(--warning-dark);
          }
        }
      }
		}	
	}

	.collapse {
		background-color: var(--light);

		&[data-color="1"] {
			border-color: var(--dark-20);
		}

		&[data-color="2"] {
			border-color: var(--warning);
		}

		&[data-color="3"] {
			border-color: var(--danger);
		}

		&[data-color="4"] {
			border-color: var(--primary);
		}

		&[data-color="5"] {
			border-color: var(--orange);
		}

		&[data-color="6"] {
			border-color: var(--purple);
		}

		&[data-color="7"] {
			border-color: var(--danger);
		}

		&[data-color="8"] {
			border-color: var(--danger);
		}

		&[data-color="9"] {
			border-color: var(--purple);
		}

		&[data-color="10"] {
			border-color: var(--success);
		}
	}
}
