.input__wrap {
    width: 100%;

    & > p {
      font-size: 16px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      color: var(--dark);
      padding-left: 10px;
      margin-bottom: 10px;
    }

    & > div {
      input {
        &:focus {
          border: 1px solid var(--primary);
        }
      }
    }
  }

  .appeal__input__dropdown {
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: translateY(100%);
    z-index: 2;
    background: var(--light);
    border: 1px solid var(--primary-dark-15);
    box-shadow: 0px 0px 20px var(--secondary-dark-10);
    border-radius: 12px;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 5px;
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--dark-15);
      border-radius: 2px;
    }

    & > li {
      padding: 5px 10px;
      width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: 0.2s linear;
      cursor: pointer;
      border-radius: 10px;
      min-height: 41px;

      & > h1 {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 2px;

        b {
          font-weight: 600;
        }
      }

      & > p {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        color: var(--dark);
        opacity: 0.5;
      }

      &:hover {
        background-color: var(--dark-5);
      }
    }

    h4 {
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      color: var(--dark);
    }
  }