@import "../../../asset/style/const.scss";

.mobile-navbar { 
    width: 100vw;
    height: 60px;
    position: fixed;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--primary);

    @media (min-width: $tablet) {
      padding-left: 5%;
      padding-right: 5%;
    }

}

.mobile-navbar__top {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 11px;

	.mobile-navbar__menu-burger {
    width: 36px;
    height: 36px;
    cursor: pointer;
    
    .icon-burger {
      position: relative;
      width: 30px;
      height: 3px;
      margin-top: 8px;
      margin-left: 3px;
      border-radius: 25px;
      background-color: var(--light);

      &::after, &::before {
        content: "";
        position: absolute;
        width: 30px;
        height: 3px;
        top: 8px;
        border-radius: 25px;
        background-color: var(--light);
      }
  
      &::after {
        top: 16px;
      }
    }

	}

  > a {
    padding-top: 4px;
  }

  .btn-create {
    width: 36px;
    height: 36px;
    background-color: var(--success);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    position: relative;

    i {
      position: absolute;
      top: 8px;
      left: 8px;
    }
  }
}

.mobile-navbar__bottom {
	width: 40%;

  .agent__info__wrap {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
    width: 100%;
    height: 52px;
    margin-top: 10px;
    padding: 0 0 12px;
    position: relative;
    background-color: transparent;
    cursor: pointer;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid var(--primary-dark-15);
      }

      .agent__info {

        h1 {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--light);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        p {
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0em;
          color: var(--light);
          opacity: 0.5;
        }
      }
    }
  }
