@import "../../asset/style/const.scss";

.rent-period-modal {
  background-color: var(--light);
  border-radius: 30px;
  padding: 30px;
  min-height: 790px;
  min-width: 871px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;

  .rent__period {
    &__nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      padding-left: 30px;
      margin-bottom: 40px;

      h1 {
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        color: var(--dark);
      }
    }

    &__hour {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 30px;
      padding: 0 70px;
      margin-bottom: 40px;

      i {
        background-color: var(--primary-dark-15);
      }
    }

    &__date {
      display: -ms-grid;
      display: grid;
      grid-template-columns: auto 1px auto;
      gap: 30px;

      .vertical__line {
        width: 1px;
        height: 100%;
        background-color: var(--primary-dark-15);
      }
    }

    &__bottom {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
  }
}

@media (max-width: $desktop-sm) {
  .rent-period-modal {
    min-height: 790px;
    min-width: 100%;
    width: 100%;
    padding: 40px;
    padding-top: 10px;
		align-items: center;
    border-radius: 0px;

		.rent__period {
			&__nav {
				gap: 15px;
				padding-left: 20px;
				padding-right: 20px;
				margin-bottom: 20px;
	
				h1 {
					font-size: 20px;
				}
			}

			&__hour {
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
				gap: 5px;
				padding: 0 20px;
				margin-bottom: 20px;
	
				i {
					background-color: var(--primary-dark-15);
				}
			}

			&__date {
				display: flex;
				flex-direction: column;
				gap: 15px;
	
				.vertical__line {
					width: 1px;
					height: 100%;
					background-color: var(--primary-dark-15);
				}
			}

		}
	}
}