.driver-wrapper {
    padding: 10px 15px;
}

.driver-header {
    display: flex;
    align-items: center;
	justify-content: space-between;
    gap: 10px;

  h1 {
    font-size: 16px;
  }

  p {
		width: 50%;
		font-size: 11px;
		color: var(--dark-50);
  }

	&__add-drivers {
		margin-left: auto;
	}
}

.driver__collapse {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.driver__block {
	width: 50%;
	padding-right: 15px;
	border-right: 1px solid gray;

	.label-block {
		margin-top: 20px;
	}
}
