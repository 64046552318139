@import "../../asset/style/const.scss";

.client-contact-card {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  background-color: var(--light);
  box-shadow: 0px 2px 10px var(--dark-5);
  border-radius: 20px;
  
    h1 {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark);
      margin-bottom: 2px;
    }
  
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      color: var(--dark);
      opacity: .6;
    }

    &__name-client {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    
        & > i {
          background-color: var(--primary-dark-15);
        }
    
        h1 {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          color: var(--dark);
          margin-bottom: 5px;
        }
    
        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark-60);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 5px;
    
          i {
            background-color: var(--dark-60);
          }
        }
      }

		&__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;

      .info-status {
        padding: 10px;
        border: 1px solid var(--dark-5);
        border-radius: 14px;
      }

        .info-phone {
          background-color: var(--dark-3);
          border-radius: 14px;
          padding: 10px;

          h1 {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            color: var(--dark);
            margin-bottom: 2px;
          }

          p {
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            color: var(--dark);
            opacity: 0.5;
          }
        }
		}

  &__share {
    position: absolute;
    top: 10px;
    right: 20px;
  }

  @media (min-width: $desktop-sm) {
    margin-top: 0;
    flex-direction: row;
    align-items: center;
    padding: 20px 20px 20px 30px;

    &__info {
      .info-phone {
        background-color: transparent;

        h1 {
          font-size: 18px;
          font-weight: 500;
          line-height: 17px;
        }
      }
		}

    &__share {
      position: relative;
      top: 0;
      right: 0;
    }
  }

}
