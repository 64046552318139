@import "../../../../../asset/style/const.scss";


.finance__top {
    margin-bottom: 20px;

    &__content {
      padding: 20px;
      
      @media (min-width: $desktop-sm) {
          display: -ms-grid;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 20px;
      }
    }
  }