@import "../../../../asset/style/const.scss";


.req-create-right {
  margin-top: 10px;
    grid-row: 3/4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
    position: relative;
    
    @media (min-width: $desktop-md) {
      grid-column: 3/4;
      grid-row: 1/2;
    
      &.filter-open {
        grid-column: 5/6;
      }
    }
}