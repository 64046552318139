@import "../../../asset/style/const.scss";

.date__picker {
    &__nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 30px;
      margin-bottom: 20px;

      h1 {
        font-size: 22px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark-70);
        text-align: center;
      }
    }

    &__weekdays {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 10px;
      padding: 0 10px;
      margin-bottom: 20px;

      li {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark-70);
      }
    }

    &__dates {
      display: -ms-grid;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 10px;

      li {
        position: relative;

        .date__circle {
          width: 45px;
          height: 45px;
          border-radius: 14px;
          border: 1px solid var(--primary-dark-15);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          cursor: pointer;
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0em;
          color: var(--dark);
          position: relative;
          transition: 0.2s linear;
          background-color: var(--light);
          transform: translateX(0.00000001%);
          z-index: 3;
          position: relative;
        }

        &.hidden {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }

        &.disabled {
          .date__circle {
            background-color: var(--dark-5);
            border-color: transparent;
            color: var(--dark-30);
            pointer-events: none;
          }
        }

        &.today {
          .date__circle {
            &::after {
              content: "";
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 14px;
              background-color: var(--primary);
              bottom: 5px;
              left: 50%;
              transform: translateX(-50%);
              transition: 0.2s linear;
            }
          }
        }

        &::after {
          content: "";
          position: absolute;
          width: 20px;
          height: 100%;
          top: 0;
          right: -5px;
          background-color: var(--primary-8);
          display: none;
        }

        &::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 100%;
          top: 0;
          left: -5px;
          background-color: var(--primary-8);
          display: none;
        }

        &.from {
          &::after {
            display: unset;
          }
        }

        &.to {
          &::before {
            display: unset;
          }
        }

        &.interval {
          .date__circle {
            background-color: #d2e1f8;
            border-color: transparent;
            color: var(--primary);
          }

          &::after {
            display: unset;
          }

          &::before {
            display: unset;
          }
        }

        &.fromInterval,
        &.toInterval {
          .date__circle {
            background-color: #d2e1f8;
            border-color: transparent;
            color: var(--primary);
          }

          &::after {
            display: unset;
          }

          &::before {
            display: unset;
          }
        }

        &.active {
          .date__circle {
            background-color: var(--primary);
            color: var(--light);

            &::after {
              background-color: var(--light);
            }
          }
        }

        &.hoverAfter.active {
          &::after {
            display: unset;
          }
        }

        &.hoverBefore.active {
          &::before {
            display: unset;
          }
        }

        &.leftSide {
          &::before {
            display: none !important;
          }
        }

        &.rightSide {
          &::after {
            display: none !important;
          }
        }

        &.first {
          &::before {
            display: none !important;
          }
        }

        &.last {
          &::after {
            display: none !important;
          }
        }
      }
    }
  }

  @media (max-width: $desktop-sm) {
    .date__picker {
      &__nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 10px;
  
        h1 {
          font-size: 22px;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: 0em;
          color: var(--dark);
        }
  
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark-70);
          text-align: center;
        }
      }
  
      &__weekdays {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: 10px;
        padding: 0 10px;
        margin-bottom: 15px;
  
        li {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark-70);
        }
      }
  
      &__dates {
        display: -ms-grid;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 5px;
        margin-top: -5px;
  
        li {
          position: relative;
  
          .date__circle {
            width: 40px;
            height: 40px;
          }

          &::before {
            left: 0px;
          }
        }
      }
    }
  }