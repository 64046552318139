@import "../../asset/style/const.scss";


.choose__car__modal {
	width: 100vw;
    background: var(--bg);
    border-radius: 30px;
    position: relative;
    padding-bottom: 100px;
    
    @media (min-width: $desktop-sm) {
        width: 1210px;
        display: grid;
        grid-template-columns: auto 350px;
    }

    &__left {
      background-color: var(--light);
      border-radius: 30px;
      padding: 20px;

      &__top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 30px;
        padding-left: 10px;

        h1 {
          font-size: 26px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0em;
          color: var(--dark);
        }
      }

      &__selects {
				margin-bottom: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				gap: 5px;

				// > li:first-child, > li:nth-child(2) {
				// 	width: 49%;
				// }       

        .auto__switch {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          gap: 10px;
          padding-left: 20px;

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
          }
        }

				@media (min-width: $desktop-sm) {
          flex-wrap: nowrap;
          
          > li:first-child, > li:nth-child(2) {
            	flex-grow: 1;
            }
        }
      }

      &__filter {
        margin-bottom: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 10px;
      }

      &__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 10px;
        min-height: 250px;
        position: relative;
      }
    }

    &__right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 10px;
      padding: 20px;
    }
  }