@import "../../../../../asset/style/const.scss";

.drafts {
    background: var(--light);
    box-shadow: 0px 2px 5px var(--dark-5);
    border-radius: 20px;
    // min-height: 280px;
    position: relative;
    padding: 10px;
    
    & > h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      padding: 10px 20px;
      padding-bottom: 0px;
      text-align: left;
      
    }
    @media (min-width: $desktop-sm) {
      margin-top: 10px;
      min-height: 180px;
      
      & > h1 {
          text-align: center;
        }
    }
    
    & > ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 5px;
      padding: 10px;
      
      @media (max-width: $desktop-sm) {
        flex-direction: row;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        scroll-snap-type: mandatory;
        
      }
      
      li {
        scroll-snap-align: center;
        border: 1px solid var(--primary-dark-15);
        border-radius: 12px;
        padding: 6px 10px;
        display: grid;
        display: -ms-grid;
        grid-template-columns: auto 40px;
        gap: 10px;
        
        
        
        @media (max-width: $desktop-sm) {
          min-width: 278px;
          max-width: 278px;
          width: 278px;
        }
    
        section {
          &:nth-child(1) {
            display: flex;
            flex-direction: column;
            justify-content: center;
    
            nav {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              padding-left: 10px;
              margin-bottom: 12px;
    
              h1 {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                margin-bottom: 2px;
                color: var(--dark);
              }
    
              p {
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0em;
                color: var(--dark);
                opacity: 0.7;
              }
            }
    
            & > p {
              font-size: 10px;
              font-weight: 400;
              line-height: 12px;
              letter-spacing: 0em;
              color: var(--dark);
              opacity: 0.4;
              margin-top: 6px;
              padding-left: 10px;
            }
          }
    
          &:nth-child(2) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            gap: 6px;
          }
        }
      }
    }
}