@import "../../../../../asset/style/const.scss";

.travel-location-form {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;

  > div:first-child {
      width: 100%; 

      @media (min-width: $tablet) {
        width: 40%;
      }

      @media (min-width: $desktop-sm) {
        width: 100%;
      }
    }


  @media (min-width: $desktop-sm) {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 180px 1fr 1fr;
    gap: 15px;
  }

      .checkbox__wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 8px;

        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--gray);
        }

        .icon__wrap {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          position: relative;
          height: 30px;

          i {
            background-color: var(--dark-20);
          }
        }
      }
    }
  