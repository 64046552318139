@import "../../asset/style/const.scss";


.confirm__modal {
    &__content {
    max-width: 375px;
    width: 345px;
    padding: 20px;

    @media (min-width: $tablet) {
        max-width: 500px;
        min-width: 450px;
        padding: 30px 30px 60px;
    }
  }

  &__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;

    h1 {
      font-size: 16px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      color: var(--dark);

      @media (min-width: $tablet) {
        font-size: 22px;
      }
    }
  }

  &__main {
    margin-bottom: 30px;
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: start;
      color: var(--dark);

      @media (min-width: $tablet) {
        font-size: 16px;
        text-align: center;
      }
    }
  }

  &__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
  }
}