@import "../../../asset/style/const.scss";

.dashboard {


    @media(min-width: $desktop-sm) {
        display: -ms-grid;
        display: grid;
        grid-template-columns: 300px 1fr;
        gap: 10px;
        max-width: 1530px;
        margin: 0 auto;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 10px; 


        &.draft-count{
            grid-template-columns: 1fr;
        }
    }

}

