.BookingPage__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.BookingPage__root {
  height: 100%;

  table {
    width: 100%;

    th {
      font-weight: 500;
      background-color: white;
      box-shadow: inset;
    }

    tbody tr:nth-child(even) {
      background-color: white;
    }

    th,
    td {
      text-align: left;
      padding: 20px;
    }

    th,
    td:not(:last-child) {
      border-right: 1px dashed rgb(228, 228, 228);
    }

    tbody tr:hover {
      background-color: var(--primary-10);
      cursor: pointer;
    }

    td {
      .gray {
        color: gray;
      }
    }
  }
}

.BookingPage__flex-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.client {
  display: flex;
  align-items: center;
  gap: 16px;
}
