
.main-menu {
  height: 100vh;
  padding-top: 30px;
  background-color: var(--primary);

    &__link {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 0px;
      padding-left: 25px;
      position: relative;
      width: 100%;
      height: 60px;

      .icon__wrap {
        background-color: var(--light-10);
        width: 36px;
        height: 36px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 50%;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--light);
      }

      .count__circle {
        position: absolute;
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em;
        padding: 2px 4px;
        background-color: var(--danger);
        color: var(--light);
        border-radius: 8px;
        top: 10px;
        left: 52px;
      }

      &.active {
        background-color: var(--light);

        .icon__wrap {
          i {
            background-color: var(--primary);
          }
        }

        p {
          color: var(--primary);
        }
      }
    }
}