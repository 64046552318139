@import "../../asset/style/const.scss";

.client__list__item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	gap: 20px;
	border: 1px solid transparent;
	border-radius: 20px;

	.client__list__item {
		&__left {
			display: flex;
			align-items: center;
			gap: 30px;

			i {
				background-color: var(--danger);
			}

			h1 {
				font-size: 20px;
				font-weight: 500;
				line-height: 24px;
				letter-spacing: 0em;
				color: var(--dark);
				margin-bottom: 2px;
			}

			p {
				font-size: 16px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0em;
				color: var(--dark);
			}
		}

		&__right {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			gap: 10px;

			.req__wrapper {
				padding: 16px 30px;
				font-size: 16px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0em;
				color: var(--dark);
			}

			.phone__wrapper {
				padding: 10px;
				background-color: var(--primary-15);
				border-radius: 14px;
				font-size: 16px;
				font-weight: 500;
				line-height: 20px;
				letter-spacing: 0em;
				color: var(--primary);

				&--red {
					color: var(--danger);
					background-color: var(--danger-15);
				}
			}

			.action__wrapper {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				gap: 10px;
				display: none;
			}

			i.icon-chevron-right {
				margin-left: 20px;
				background-color: var(--dark);
			}
		}
	}

	
	@media (min-width: $desktop-sm) {
		padding: 20px 30px;

		&:hover {
			.client__list__item {
				&__right {
					.action__wrapper {
						display: flex;
					}
				}
			}
		}
	}
}