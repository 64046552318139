.extra-fields__card {
    padding: 10px 15px;
    margin-bottom: 10px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.extra-fields__approved {
    width: 50%;
    margin-left: auto;

    .card {
        padding: 8px 16px;
    }
}