@import "../../../asset/style/const.scss";

.catalog-wrap {
    gap: 10px;
    
    
    @media(min-width: $desktop-sm) {
      display: grid;
      grid-template-columns: 220px 800px 240px;
      justify-content: center;
    }
    @media(min-width: $desktop-md) {
      grid-template-columns: 250px 900px 270px;
    }
}


  .catalog {
    &__left {
      padding-top: 0px;
    }

    &__middle {
      background-color: var(--light);
      box-shadow: 0px 2px 5px var(--dark-5);
      position: relative;
      height: calc(100vh - 60px);
      overflow: hidden;
      border-radius: 16px;

      @media(min-width: $desktop-sm) {
        height: 96vh;
        min-height: 1080px;
      }
    }

    &__right {
      padding-top: 0px;
    }
  }