@import "../../../asset/style/const.scss";

.req-create-wrap {
  position: relative;

  @media (min-width: 1280px) {
    display: grid;
    grid-template-columns: 570px 1fr;
    grid-template-rows: 465px 0.5fr 1fr;
    justify-content: center;
    gap: 8px;
    background-color: var(--bg);
    position: relative;
    transform: translateX(0.00001%);
    transition: 5s;

    .left__filter,
    .right__filter {
      padding-top: 15px;
    }

    @media (min-width: 1600px) {
      transition: 0.3s;
      grid-template-columns: 600px 1fr 330px;
      grid-template-rows: 1fr;
    }

    @media (min-width: 1800px) {
      transition: 0.3s;
      grid-template-columns: 700px 1fr 360px;
      grid-template-rows: 1fr;
    }

    @media (min-width: 2100px) {
      transition: 0.3s;
      grid-template-columns: 770px 800px 360px;
      grid-template-rows: 1fr;
      justify-content: center;
      gap: 10px;
    }
  }
}
