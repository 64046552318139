
.modal-filter__wrap {
	position: fixed;
	top: -20px;
	right: -20px;
	height: 150vh;
	width: 120%;
  z-index: 15;
  background-color: var(--dark-60);
  transition: 0.3s linear;
  display: flex;
  justify-content: flex-end;
  visibility: hidden;
  opacity: 0.5;
}

.modal-filter__main {
	max-width: 800px;
  width: 20%;
  max-height: 100%;
  overflow-y: auto;
  position: absolute;
  right: -100%;
  height: 100%;
  transition: 0.3s linear;
  scrollbar-width: none;
	background-color: var(--light);


	.modal-filter__nav {
		max-width: 800px;
		width: 100%;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 30px;
		background-color: var(--light);
	}
}


.modal-filter__open.modal-filter__wrap {
    opacity: 1;
    visibility: visible;

		.modal-filter__main {
			right: 0;
		}
}