@import "../../asset/style/const.scss";

.req-cancel-card {
  background-color: var(--danger-10);
  border: 1px solid var(--primary-dark-15);
  border-radius: 20px;
  padding: 20px;
  display: flex;
	flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
	margin-bottom: 10px;

	@media (min-width: $desktop-sm) {
		flex-direction: row;
		align-items: center;
		padding: 20px 20px 20px 30px;
	}

  h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--dark);
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--dark);
    opacity: 0.7;
  }

  .action__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
  }

  &[data-type="2"] {
    background: linear-gradient(0deg, rgba(255, 196, 43, 0.1), rgba(255, 196, 43, 0.1)),#ffffff;
  }

  &[data-type="10"] {
    background-color: var(--success-10);
  }

}
