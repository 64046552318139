@import '../../asset/style/const.scss';

.req__cancel__modal {
    padding: 20px;
		min-width: 300px;
		max-width: 500px;
		height: 80vh;

    @media (min-width: $desktop-sm) {
			height: 100%;
      padding: 30px;
      min-width: 450px;
      max-width: 500px;
    }

    &__top {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 30px;

      h1 {
        font-size: 22px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        color: var(--dark);
      }
    }

    &__body {
      margin-bottom: 30px;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        opacity: 1;
        margin-bottom: 30px;
        text-align: center;
      }
    }

    &__bottom {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
  }