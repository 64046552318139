@import "../../asset/style/const.scss";

.client__info__card {
    &__content {
        padding: 20px;
        @media (min-width: $desktop-sm) {
            padding: 30px 30px 30px 60px;
        }
    }

    &__nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
			flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      margin-bottom: 15px;

			@media (min-width: $desktop-sm) {
				gap: 30px;
				margin-bottom: 30px;
			}
    }

		&__nav-back {
			order: -2;

			@media (min-width: $desktop-sm) {
				order: inherit;
			}
		}

    &__name {
			margin-right: auto;
			width: 100%;
      h1 {
				font-size: 26px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        color: var(--dark);
      }
			
      p {
				font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: var(--dark);
      }

			@media (min-width: $desktop-sm) {
				width: 40%;
			}
    }
		
    &__action {
			order: -1;
			margin-left: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;

			@media (min-width: $desktop-sm) {
				order: inherit;
			}
    }

    &__main {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 30px;
      margin-bottom: 30px;
			
			@media(min-width: $desktop-sm) {
				flex-direction: row;
				align-items: center;
			}
    }

    &__contact {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--dark-70);
      }

      h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }
    }

    &__footer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
			flex-wrap: wrap;
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 30px;
    }

    &__note {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 10px;

      h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--dark-70);
        max-width: 645px;
      }
    }
  }